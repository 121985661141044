import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ErrorUp, SuccessUp } from '../base'

function Feedback() {
    const user = useSelector((state) => state.userInfo.user)
    const [feed, setFeed] = useState('')
    const [message, setMessage] = useState(null)

    async function feedback() {
        setMessage(null)
        if (feed === '') {
            setMessage('Please give feedback!')
        }
        else {
            try {
                const token = JSON.parse(localStorage.getItem('token'))
                await axios.post(`http://127.0.0.1:8000/api/app/feedback/`, { 'feedback' :feed }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setMessage('Thanks for the feedback !')
            }
            catch (error) {
            }
        }
        setFeed('')
    }

    return (
        <>
            {
                user ? <>
                    <label>Feedback : </label>
                    <input type='text' value={feed} onChange={(e) => setFeed(e.target.value)} />
                    <button onClick={() => feedback()}>Submit</button>
                    {message !== null ? <SuccessUp removeMessage={setMessage} message={message} /> : <> </>}
                </> : <> </>
            }
        </>
    )
}

export default Feedback;