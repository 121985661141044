import React from 'react'
import { useNavigate } from 'react-router-dom'



const SingleCategory = React.memo(({item, dukandar = null}) => {
  const navigate = useNavigate()
  return (
    item && <div className='col-sm-6 col-lg-4' style={{overflow : 'hidden'}}>
        <div className='singlecategory' data-aos="zoom-in-up">
          <img src = {item.image} alt={item.name}/>
           {/* <img src = {`${url}/${item.image.split(':8000/')[1]}`} alt={`${item.name}`}/> */}
           <div className='singlecategorydetail'>
            <h1 className='h5'>Category : {item.name}</h1>
           <h1 className='h5'>Total Products : {item.total_products}</h1>
          { dukandar ?
           <button className='secondry-button h6 mb-3 mt-3' onClick = {()=>navigate(`/dukandar/product/?category=${encodeURIComponent(item.slug)}`)}>View products</button> :
           <button className='secondry-button h6 mb-3 mt-3' onClick = {()=>navigate(`/category/${item.slug}/products/`)}>View products</button>
          }
           </div>
        </div>
    </div>
  )
})

export default SingleCategory