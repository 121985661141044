import axios from "axios";
import React, { useEffect, useState } from "react";
import { ErrorUp, SuccessUp, catchError, errorName, getToken, url } from "../base";
import { useDispatch } from "react-redux";
import { changeTotalItem } from "../redux/UserInfoSlice";
import { changeTotalPrice } from "../redux/CartSlice";
import { Link } from 'react-router-dom'

const CartItem = React.memo(({ item, setPerror }) => {
    const [quantity, setQuantity] = useState(0)
    const [price, setPrice] = useState(0)
    const [removed, setRemoved] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [offerShow, setOfferShow] = useState(false)
    const dispatch = useDispatch()

    async function updateCartItem(item, type) {
        try {
            if (type === 'inc' || (type === 'dec' && quantity > 1)) {
                setUpdating(true) 
                setError(null)
                setSuccess(null)
                const token = getToken()
                const request = await axios.put(`${url}/api/cartItem/?item=${item.id}&type=${type}&order=${item.order}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                if (request.data.error_message) {
                    request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) :
                    setError(request.data.error_message)
                }
                else if (request.data.success) {
                    setSuccess(request.data.success)
                    if (type === 'inc') {
                        setQuantity((prev) => {
                            return prev + 1
                        })
                        // setPrice((prev) => {
                        //     return prev + item.product_price
                        // })
                        setPrice(request.data.price)
                        dispatch(changeTotalPrice({ 'type': 'inc', 'amount': request.data.total_price }))
                    }
                    else if (type === 'dec' && quantity > 1) {
                        setQuantity((prev) => {
                            return prev - 1
                        })
                        // setPrice((prev) => {
                        //     return prev - item.product_price
                        // })
                        setPrice(request.data.price)
                        dispatch(changeTotalPrice({ 'type': 'dec', 'amount': request.data.total_price }))
                    }
                }
                setUpdating(false)
            }
        }
        catch (error) {
            setUpdating(false)
            setPerror(catchError(error))
        }
    }

    async function deleteCartItem(item) {
        try {
            setUpdating(true)
            setError(null)
            setSuccess(null)
            const token = getToken()
            const request = await axios.delete(`${url}/api/cartItem/?item=${item.id}&order=${item.order}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data.error_message) {
                request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) :
                setError(request.data.error_message)
            }
            else if (request.data.success) {
                dispatch(changeTotalItem('dec'))
                dispatch(changeTotalPrice({ 'type': 'del', 'amount': price }))
                setSuccess(request.data.success)
                setRemoved(true)
            }
            setUpdating(false)
        }
        catch (error) {
            setUpdating(false)
            setPerror(catchError(error))
        }
    }

    useEffect(() => {
        setQuantity(item.quantity)
        setPrice(item.quantity * item.product_price)
    }, [item])

    
    return (
        <>
            {
                removed === false ?
                    <div className="col-sm-10 offset-sm-1 offset-md-0 col-md-6 col-lg-4">
                        <div className="mcartitem">
                            <img src={`${url}${item.image}`} alt={`${item.product_name} img`} className="img-fluid" />
                            <div className="mcartitemdetail mt-2 mb-3 ">
                                <Link to={`/product/${item.product_name.replace(/\s+/g,"-")}/`} className="h6 text-center">{item.product_name}</Link>
                                <div className="ps-5 pe-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <button className="secondry-button plus h6 m-0" onClick={() => updateCartItem(item, 'inc')} disabled={updating ? true : false}><i className="fa-solid fa-plus h6"></i></button>
                                    <h2 className="h6">{quantity} </h2>
                                    <button className="secondry-button minus h6 m-0" onClick={() => updateCartItem(item, 'dec')} disabled={updating ? true : false}><i className="fa-solid fa-minus h6"></i></button>
                                    <h2 className="h6">{price} </h2>
                                    <button className="delete h5" onClick={() => deleteCartItem(item)} disabled={updating ? true : false}><i className="fa-solid fa-trash h5"></i></button>
                                </div>
                            </div>
                            {
                                item.offer ? <div className='offer h6' onClick={() => setOfferShow(!offerShow)}><i className="fa-solid fa-layer-group fa-fade h6"></i></div> : <></>
                            }
                            <div className={offerShow ? 'offer-show' : 'offer-show hide'} style={{ fontSize: '0.8rem' }}>
                                {item.offer}
                            </div>
                        </div>

                        {error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>}
                    </div> : <></>
            }
        </>
    )

})

export default CartItem;