import React from 'react'
import notfound from '../../images/notfound.png'
import { useNavigate } from 'react-router-dom'
import { changeTotalItem, userInfoDefault, dropdown, notificationChange } from "../../redux/UserInfoSlice";
import { useDispatch } from 'react-redux';

function NoData({message, height, button, dukandar}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const path = window.location.pathname

  function change() {
    dispatch(dropdown(false))
    localStorage.removeItem('token')
    dispatch(userInfoDefault())
    dispatch(changeTotalItem('set0'))
    dispatch(notificationChange())
    navigate(`/user/createUser/?redirect=${path}`)
  }


  return (
    <div className='container d-flex' style={{justifyContent : 'center', alignItems : 'center', minHeight : height ? height : '80vh', flexDirection : 'column', gap : '30px'}}>
      <img src={notfound} alt = 'Page Not Found' className='img-fluid' style={{maxWidth : '250px'}}/>
      <h5 className='h5'>{message}</h5>
      {button ? <button className='h6 secondry-button ps-3 pe-3 m-0' style={{width : 'fit-content'}} onClick={()=>change()}>Create account</button> : dukandar ? <div > Contact Us - 6260823922 </div> : <> </>}
    </div>
  )
}

export default NoData

// {button ? <button className='h6 secondry-button ps-3 pe-3 m-0' style={{width : 'fit-content'}} onClick={()=>change()}>Create account</button> : <> </>}
