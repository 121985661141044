import { Header, Footer } from './components/index'
import { NotFound404 } from './base/index'
import { Home, Login, Create, Forgot, Profile, Category, CategoryProduct, DetailProduct, Cart, Orders, Notification, SnapNShop, ApniDukan, DukanProfile, DukanCategory, DukanNotificaiton, DukanOrder, DukanProduct, DukanUser, BillDesk  } from './pages/index'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { userInfo, basicInfo } from './redux/UserInfoSlice'
import { useDispatch, useSelector } from 'react-redux'


// import AOS from 'aos';
// import 'aos/dist/aos.css';

function App() {
  const dispatch = useDispatch()
  const userInfoError = useSelector((state) => state.userInfo.userInfoError)
  
  useEffect(() => {
    // AOS.init({
    //   duration : 1000
    // })
     dispatch(userInfo())
  }, [dispatch])

  useEffect(()=>{
    userInfoError !== null ? dispatch(basicInfo()) : <></>
  },[userInfoError])
  
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  

  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
        <Header />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/user/loginUser/' element={<Login />}></Route>
          <Route path='/user/createUser/' element={<Create />}></Route>
          <Route path='/user/apniDukan/' element={<ApniDukan />}></Route>
          <Route path='/user/profile/' element={<Profile />}></Route>
          <Route path='/user/forgotPassword/' element={<Forgot />}></Route>
          <Route path='/category/' element={<Category />}></Route>
          <Route path='/category/:category/products/' element={<CategoryProduct />}></Route>
          <Route path='/product/SnapNShop/' element={<SnapNShop/>}></Route>
          <Route path='/product/:product/' element={<DetailProduct />}></Route>
          <Route path='/cartItem/' element={<Cart />}></Route>
          <Route path='/myOrders/' element={<Orders />}></Route>
          <Route path='/notification/' element={<Notification />}></Route>
          <Route path='/dukandar/profile/' element={<DukanProfile />}></Route>
          <Route path='/dukandar/users/' element={<DukanUser />}></Route>
          <Route path='/dukandar/category/' element={<DukanCategory/>}></Route>
          <Route path='/dukandar/product/' element={<DukanProduct/>}></Route>
          <Route path='/dukandar/product/:product/' element={<DetailProduct />}></Route>
          <Route path='/dukandar/addProduct/' element={<Notification />}></Route>
          <Route path='/dukandar/updateProduct/' element={<Notification />}></Route>
          <Route path='/dukandar/notification/' element={< DukanNotificaiton/>}></Route>
          <Route path='/dukandar/orders/' element={<DukanOrder />}></Route>
          <Route path='/dukandar/billDesk/' element={<BillDesk />}></Route>
          <Route path='/*' element={<NotFound404 />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

