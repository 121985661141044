import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import { ErrorUp, SuccessUp, Sloading, url } from '../../base/index';
import { createUser, userDefault } from '../../redux/UserSlice'
import { SmallLogo } from '../../components/index';
import { dropdown } from '../../redux/UserInfoSlice';
import { Helmet } from 'react-helmet'
import axios from 'axios';
import { errorName, pattern } from '../../base';

function Create() {
  const user = useSelector((state) => state.userInfo.user)
  const { userError, userSuccess, userLoading } = useSelector((state) => state.user)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const [type, setType] = useState('password')
  const next = new URLSearchParams(location.search).get('redirect');
  const [redirect, setRedirect] = useState('/')
  const [dukans, setDukans] = useState(null)
  const [pin, setPin] = useState('')
  const [createData, setCreateData] = useState({
    'username': '',
    'email': '',
    'password': '',
    'default_dukan_id': '',
    'default_dukan_name': ''
  })

  // document.addEventListener('keypress', handleEnter)
  // function handleEnter(event) {
  //   event.key === 'Enter' ? create() : <></>
  // }

  // function 
  function change(e) {
    setCreateData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  async function searchApniDukan() {
    setError(null)
    setDukans(null)
    try {
      const request = await axios.get(`${url}/api/user/searchApniDukan/?pin=${pin}`)
      request.data.error_message ? setError('Oops! No dukan found with this pin.') : setDukans(request.data)
    }
    catch (error) {
      setError('Oop! No dukan found with this pin.')
    }
  }



  function selectDukan(item) {
    setCreateData((prev) => {
      return {
        ...prev,
        default_dukan_id: item.id,
        default_dukan_name: item.name
      }
    })
    setDukans(null)
  }

  function create() {
    const trimed_username = createData['username'].replace(/\s+/g, '')
    const trimed_password = createData['password'].replace(/\s+/g, '')
    const trimed_email = createData['email'].trim()
    if (trimed_username === '')
      setError('Enter username !')
    else if (!pattern.usernamePattern.test(trimed_username))
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
    else if (trimed_email === '' || !pattern.emailPattern.test(trimed_email))
      setError('Enter a valid email !')
    else if (trimed_password.length < 6)
      setError('Enter minimum 6 digit password !')
    else if (createData.default_dukan_id === '')
      setError('Select apni dukan !')
    else {
      dispatch(createUser({ 'username': createData.username, 'email': createData.email, 'password': createData.password, 'default_dukan_id' : createData.default_dukan_id }))
    }
  }


  // useEffect hook
  useEffect(() => {
    dispatch(dropdown(false))
  }, [])


  useEffect(() => {
    next === null ? setRedirect('/') : setRedirect(next)
  }, [next])

  useEffect(() => {
    user !== null ? navigate('/') : <></>
  }, [user])


  useEffect(() => {
    if (userSuccess === true) {
      setSuccess('User has been successfully created!')
      const timeout = setTimeout(() => {
        window.location.href = redirect
      }, 100);
      return () => clearTimeout(timeout);
    }
    else if (userError === 'Username : User with this username already exists.') {
      setError('Username already exists! Try new.')
      dispatch(userDefault())
    }
    else if (userError === 'Email : User with this email already exists.') {
      setError('Email already exists! Try new.')
      dispatch(userDefault())
    }
    else if (userError === errorName.networkError || userError === errorName['404Error'] || userError === errorName.internalError) {
      dispatch(userDefault())
      setError('No connection with server! Please try again.')
    }
    else if (userError !== null) {
      setError(userError)
      dispatch(userDefault())
    }
  }, [userError, userSuccess])


  return (
    <section className='create container' style={{overflow : 'visible'}}>
      <Helmet>
        <title>Create Account</title>
      </Helmet>
      <div className='row' style={{ width: '100%', overflow : 'visible' }}>

        <div className='logoside col-12 col-md-6 d-flex mb-3' style={{ justifyContent: 'center', flexDirection: 'column', overflow: 'visible', alignItems: 'center' }}>
          <SmallLogo />
          <div className='row' style={{ marginTop: '-30px', justifyContent: 'center', overflow: 'visible' }}>
            <div className='col-3' style={{ position: 'relative', padding: '0px 0px 0px 5px' }}>
              <label className='h6' >Pin : </label>
              <input className='h6 ' style={{ flex: 1, padding: '1px' }} value={pin} onChange={(e) => setPin(e.target.value)} type='number' placeholder='Pin code' />
              <i className="fa-solid fa-magnifying-glass h5" onClick={() => searchApniDukan()} style={{ position: 'absolute', right: '3px', cursor: 'pointer', color: 'red', backgroundColor: 'white' }}></i>
            </div>
            <div className='col-9 col-sm-6 col-md-9' style={{ overflow: 'visible', position: 'relative' }}>
              <div className='searchlist'>
                <div className='searchlistinput' style={{ width: '100%' }}>
                  <label className='h6' >Apni Dukan : </label>
                  <input className="h6" style={{ flex: 1, padding: '1px' }} value={createData.default_dukan_name} disabled={true} placeholder="अपनी पसंदीदा दुकान चुने " />
                  <i className="fa-solid fa-xmark h6" onClick={() => setDukans(null)} style={{ position: 'absolute', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
                </div>
                <div className="searchlistdiv" style={{ display: `${dukans === null ? 'none' : 'flex'}`, top: '44px', width: '100%' }}>
                  <div style={{ width: '100%' }}>{
                    dukans && dukans.map((item, key) => {
                      return (
                        <div className="searchlistvalue" key={key} >
                          <h1 className="h6" key={key} onClick={() => selectDukan(item)}>{item.name} </h1>
                          <hr style={{ margin: '0px' }} />
                        </div>
                      )
                    })
                  }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='formside col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1' >
          <div className='card create-card gap-2' data-aos="fade-down" >
            <div className='h4 text-center mb-2' style={{ textDecoration: 'underline' }}>Create Account</div>
            <label className='h5'>Username : </label>
            <input className='h6' type='text' name='username' value={createData.username} onChange={(e) => change(e)} placeholder='Enter a unique username' />
            <label className='h5'>Email : </label>
            <input className='h6' type='email' name='email' value={createData.email} onChange={(e) => change(e)} placeholder='example@gmail.com' />
            <label className='h5'>Password : </label>
            <div style={{ position: 'relative' }}>
              <input className='h6' type={`${type}`} name='password' value={createData.password} onChange={(e) => change(e)} placeholder='Enter minimum 6 digit password' />
              {
                createData.password !== '' ? type === 'password' ? <i className="fa-solid fa-eye" style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '92%' }} onClick={() => setType('text')}></i> : <i className="fa-solid fa-eye-slash" style={{ cursor: 'pointer', position: 'absolute', top: '0', left: '92%' }} onClick={() => setType('password')}></i> : <></>
              }
            </div>


            {
              userLoading === true ?
                <button className='primary-button h5 d-flex' style={{ justifyContent: 'center', alignItems: 'center' }} onClick={() => create()} disabled={userLoading ? true : false}><Sloading color={'red'} width={'15px'} height={'fit-content'} /></button> :
                <button className='primary-button h5 pt-1 pb-1' onClick={() => create()} disabled={userLoading ? true : false}>Register</button>
            }
            {/* <button className='primary-button h5' onClick={() => create()} disabled = {userLoading ? true : false}>Register</button> */}
            <div className='divider h6'>OR</div>
            <div className='h6' style={{ textAlign: 'center' }}>Already have account ?</div>
            <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/loginUser/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Login</button>
            <div className='h6' style={{ textAlign: 'center' }}>Forgot Mahajan Kirana passowrd ?</div>
            <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/forgotPassword/?redirect=${redirect}`)} disabled={userLoading ? true : false}>Forgot password</button>
          </div>
        </div>
      </div>
      {
        error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
      }
    </section>
  )
}

export default Create