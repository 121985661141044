import React, { useState } from 'react'
import { resetPassword} from '../redux/UserSlice'
import { ErrorUp} from '../base/index'

const ResetPassword = React.memo(({dispatch}) => {
    const [error, setError] = useState(null)
    const [password, setPassword] = useState({
        'new_password': '',
        'old_password': ''
    })

    function changePassword(e) {
        setPassword((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    function reset() {
        const trimed = password.new_password.trim()
        if (trimed.length < 6)
            setError('Enter minimum 6 digit new password !')
        else {
            dispatch(resetPassword({ 'old_password': password.old_password, 'new_password': password.new_password }))
        }
    }


    return (
        <div className='row'>
            <div className='h4 heading'>Change password</div>
            <div className='col-sm-6 col-md-4'>
                <label className='h5'>Current password </label>
                <input className='h6' type='text' name='old_password' onChange={(e) => changePassword(e)} value={password.old_password} placeholder='Enter your current password' />
            </div>
            <div className='col-sm-6 col-md-4 mt-3 mt-sm-0'>
                <label className='h5'>New Password </label>
                <input className='h6' type='text' name='new_password' onChange={(e) => changePassword(e)} value={password.new_password} placeholder='Enter a new strong password' /><br />
            </div>
            <div className='col-md-4 text-md-start text-center pt-2 pb-2 mt-4 mt-md-0'>
                <button className='primary-button h6 pt-1 pb-1' style={{ maxWidth: '200px' }} onClick={() => reset()}  >Update password</button> <br />
            </div>
            {
                error ? <ErrorUp message={error} removeMessage={setError}/> : <></>
            }
        </div>
    )
})

export default ResetPassword