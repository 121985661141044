import React, { useState } from "react";
import { changeTotalItem } from "../redux/UserInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ErrorUp, SuccessUp, catchError, url, Sloading, errorName } from "../base/index";

const SingleOrderItem = React.memo(({ item, setPerror }) => {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [offerShow, setOfferShow] = useState(false)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const dispatch = useDispatch()

    async function add() {
        setSuccess(null)
        setError(null)
        try {
            setLoading(true)
            const token = JSON.parse(localStorage.getItem('token'))
            const request = await axios.post(`${url}/api/cartItem/`, { 'product': item.product_id, 'quantity': item.quantity },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            if (request.data && request.data.error_message) {
                request.data.error_message === errorName.internalError ? setError('Oops! Can not add item, please try again.') :
                setError(request.data.error_message)
            }
            else {
                request.data.success.includes('added') ? dispatch(changeTotalItem('inc')) : <></>
                setSuccess(request.data.success)
            }
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
            const d = catchError(error)
            d === errorName.internalError || d === errorName.networkError || d === errorName["404Error"] ? setError('Oops! Can not add item, please try again.') :
                d === errorName.authenticationError ? setError('Oops! Session expire, Please login again') : setError(error)
        }

    }

    return (
        <>
            <div className="orderitem mt-3" style={{ position: 'relative' }}>
                <div className="row">
                    <div className="col-3 p-0">
                        <div className="orderimg" >
                            <img src={`${url}${item.image}`} className="img-fluid" alt={`${item.product_name}`} />
                        </div>
                    </div>
                    <div className="detail col-9">
                        <div className="row g-2">
                            <div className="col-12 text-start">
                                <h1 className="h6">{item.product_name}</h1>
                            </div>
                            <div className="col-6">
                                <h1 className="h6">Quantity : {item.quantity}</h1>
                            </div>
                            <div className="col-6">
                                <h1 className="h6">Total : {item.price} Rs.</h1>
                            </div>
                            {is_dukandar === false ? <>
                                <div className="col-9">
                                    <h1 className="h6">Today's Price : {item.product_price} Rs.</h1>
                                </div>
                                <div className="col-3">
                                    {
                                        loading === true ?
                                            <button className='secondry-button h6 d-flex' style={{ justifyContent: 'center', alignItems: 'center' }} ><Sloading color={'red'} width={'16px'} height={'fit-content'} /></button> :
                                            <button className='secondry-button h6' onClick={() => add()}><i className="fa-solid fa-cart-shopping" style={{overflow : 'hidden'}}></i></button>  
                                    }
                                    {/* <button className="secondry-button h6" disabled={loading ? true : false} onClick={() => add()}><i className="fa-solid fa-cart-shopping"></i></button> */}
                                </div>
                            </> : <div className="col-12">
                                <h1 className="h6">Today's Price : {item.product_price} Rs.</h1>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    item.item_offer ? <div className='offer h6' onClick={() => setOfferShow(!offerShow)}><i className="fa-solid fa-layer-group fa-fade h6"></i></div> : <></>
                }
                <div className={offerShow ? 'offer-show' : 'offer-show hide'} style={{ fontSize: '0.8rem' }}>
                    {item.item_offer}
                </div>
                {
                    error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                }
            </div>
            <hr className="mt-1 mb-1"></hr>
        </>
    )
})

export default SingleOrderItem;