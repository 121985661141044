import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrder, getLatestOrder, incOrderPage } from "../../redux/OrderSlice";
import { errorName, Loading, message, NoConnection, NoData, NotAuthorized, Sloading } from "../../base/index";
import { SingleOrder } from "../../components/index";
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function Orders() {
    const { orderLoading, orderError, orders, orderLastPage, orderPage, orderFirstTime } = useSelector((state) => state.order)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const path = window.location.pathname
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const refreshParam = searchParams.get('refresh');

    function load() {
        setIsMoreLoading(true)
        dispatch(getAllOrder(orderPage + 1))
        dispatch(incOrderPage())
    }
    useEffect(() => {
        dispatch(dropdown(false))
        // refreshParam === 'true' ? dispatch(getLatestOrder()) : <></>
    }, [dispatch, refreshParam])


    useEffect(() => {
        orderFirstTime === true ? dispatch(getAllOrder(1)) : <></>
    }, [orderFirstTime])

    useEffect(() => {
        orderLoading === false ? setIsMoreLoading(false) : <></>
    },[orderLoading])
 
    return (
        <section>
            <Helmet>
                <title>My Orders</title>
            </Helmet>
            {
                (orderPage === 1 && orderLoading) || userInfoLoading === true ? <Loading /> :
                    orderError === errorName.networkError || orderError === errorName["404Error"] || orderError === errorName.internalError || error === errorName.internalError || error === errorName["404Error"] || error === errorName.networkError ? <NoConnection /> :
                        orderError === errorName.authenticationError || error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                            orderError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                orders !== null ?
                                    <div className='order container'>
                                        <h1 className='heading h4'>Your previous orders!</h1>
                                        {
                                            orders.map((item, key) => {
                                                return (
                                                    <SingleOrder item={item} key={key} setError={setError} />
                                                )
                                            })
                                        }

                                        {
                                            <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                {
                                                    isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                        orderLastPage === false ?
                                                            <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                }
                                            </div>
                                        }
                                    </div> : <NoData message={'Oops! No order found, Shop now. '} />
            }
        </section>
    )
}

export default Orders