import React from 'react'
import error404 from '../../images/error404.png'
import { Helmet } from 'react-helmet'

function NotFound404() {
  return (
    <section className='d-flex contianer' style={{justifyContent : 'center', alignItems : 'center'}}>
      <Helmet>
        <title>404</title>
      </Helmet>
      <img src={error404} alt='404 Error' className='img-fluid'/>
    </section>
  )
}

export default NotFound404