import React, { useEffect } from 'react'
import { changeTotalItem, userInfoDefault } from '../../redux/UserInfoSlice'
import { useDispatch } from 'react-redux'
import noconnection from '../../images/noconnection.png'
import { Helmet } from 'react-helmet'

function NoConnection() {
  const dispatch = useDispatch()
  useEffect(()=>{
     dispatch(userInfoDefault())
     localStorage.removeItem('token')
     dispatch(changeTotalItem('set0'))
  },[dispatch]
  
  )
  return (
    <div className='container d-flex' style={{justifyContent : 'center', alignItems : 'center', minHeight : '80vh', gap : '30px'}}>
        <Helmet>
          <title>No Connection</title>
        </Helmet>
          <img src={noconnection} alt='No Connection' className='img-fluid' style={{maxWidth : '250px'}}/>
    </div>
  )
}

export default NoConnection