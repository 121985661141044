import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dukandarDefault, getDukanNotification } from "../../redux/DukandarSlice";
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { errorName, message, Loading, NoData, NotAuthorized, NoConnection, ErrorUp, SuccessUp } from "../../base/index";
import { AddDukanNotification, DukanNotificationList } from "../../components";

function DukanNotification() {
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const { dukanNotification, dukandarLoading, dukandarError, dukandarSuccess } = useSelector((state) => state.dukandar)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [disable, setDisable] = useState(false)
    const dispatch = useDispatch()
    const path = window.location.pathname

    useEffect(() => {
        dispatch(dropdown(false))
        if (dukanNotification === null) {
            dispatch(getDukanNotification());
        }
    }, [dispatch, dukanNotification])


    useEffect(() => {
        dukandarLoading ? setDisable(true) : setDisable(false)
    }, [dukandarLoading])

    useEffect(() => {
        if (dukandarError !== errorName.networkError && dukandarError !== errorName['404Error'] && dukandarError !== errorName.internalError && dukandarError !== errorName.authenticationError && dukandarError !== errorName.notDukandar && dukandarError !== null) {
            setError(dukandarError)
            dispatch(dukandarDefault())
        }
        if(dukandarSuccess) {
            setSuccess(dukandarSuccess) 
            dispatch(dukandarDefault())
        }
        
    }, [dukandarError, dukandarSuccess])

    return (
        <section>
            <Helmet>
                <title>Dukan Notification</title>
            </Helmet>

            <div className='container'>
                {
                    dukandarLoading || userInfoLoading ? <Loading /> :
                        dukandarError === errorName.networkError || dukandarError === errorName["404Error"] || dukandarError === errorName.internalError ? <NoConnection /> :
                            dukandarError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                    <>
                                        <AddDukanNotification disable={disable} setError={setError} />
                                        {
                                            dukanNotification !== null && dukanNotification.length !== 0 ? <div className="notification" style={{width : '100%'}}>
                                                <h4 className='h4 heading'> Notifications </h4>
                                                {
                                                    dukanNotification.map((item, key) => {
                                                        return (
                                                            <DukanNotificationList key={key} item={item} disable={disable} />
                                                        )
                                                    })
                                                }
                                            </div> : <NoData message='Oops! No Notification found.' />}
                                        {
                                            error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                                        }
                                    </>
                }
            </div>

        </section>
    )
}

export default DukanNotification