import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { errorName, handleRequest, catchError, url, getToken } from '../base/index'
// creating intial state

const intialState = {
    dukandarError: null,
    dukandarLoading: false,
    dukandarSuccess: null,

    dukanCategory: null,
    dukanCategoryPage: 1,
    dukanCategoryFirstTime: true,
    searchedCategory : null,
    dukanAllCategory: [],
    dukanCategoryLastPage : false,

    categoryName: '',
    categoryProduct: null,
    searchedProduct: null,

    dukanUser: null,
    dukanUserPage: 1,
    dukanUserFirstTime: true,
    dukanUserLastPage : false,

    dukanProfile: null,
    dukanNotification: null,

    dukanOrder: null,
    dukanOrderPage: 1,
    dukanOrderFirstTime: true,
    dukanOrderLastPage : false,
    orderFilter: {
        'is_paid': 'all',
        'is_deliverd': 'all'
    },

    dukanBillDeskOrder : null,
    dukanBillDeskOrderPage : 1,
    dukanBillDeskOrderLastPage : false
}

const baseUrl = `${url}/api/dukandar`

const getDukanUser = createAsyncThunk('getDukanUser', async (page, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getDukanUser/`, {
            params: {
                page: page
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanAllCategory = createAsyncThunk('getDukanAllCategory', async (_, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanCategory/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                only_name: 'yes'
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanCategory = createAsyncThunk('getDukanCategory', async (page, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanCategory/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                page: page
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const addDukanCategory = createAsyncThunk('addDukanCategory', async ({ data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${baseUrl}/dukanCategory/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getSearchedCategory = createAsyncThunk('getSearchedCategory', async (slug, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getSearchedCategory/?category=${slug}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const updateDukanCategory = createAsyncThunk('updateDukanCategory', async ({ category, data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.put(`${baseUrl}/dukanCategory/?category=${category}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getCategoryProduct = createAsyncThunk('getCategoryProduct', async (category, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/dukanProduct/?category=${category}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const addDukanProduct = createAsyncThunk('addDukanProduct', async ({ data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${baseUrl}/dukanProduct/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getSearchedProduct = createAsyncThunk('getSearchedProduct', async ({item, billDesk}, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/getSearchedProduct/?product=${item}&billDesk=${billDesk}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const updateDukanProduct = createAsyncThunk('updateDukanProduct', async ({product, data }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.put(`${baseUrl}/dukanProduct/?product=${product}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanOrder = createAsyncThunk('getDukanOrder', async ({ page, filters }, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/order/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: filters
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanNotification = createAsyncThunk('getDukanNotification', async (_, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.get(`${baseUrl}/notification/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const addDukanNotification = createAsyncThunk('addDukanNotification', async (data , { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${url}/api/dukandar/notification/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const deleteDukanNotification = createAsyncThunk('deleteDukanNotification', async (id, { rejectWithValue }) =>{
    try {
        const token = getToken()
        const request = await axios.delete(`${url}/api/dukandar/notification/?notification=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const getDukanProfile = createAsyncThunk('getDukanProfile', async (_, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.get(`${baseUrl}/dukanProfile/`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const udpateDukanProfile = createAsyncThunk('udpateDukanProfile', async ({ data }, { rejectWithValue }) => {
    try {
        const token = getToken();
        const request = await axios.put(`${baseUrl}/dukanProfile/`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })

        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
});

const getDukanBillDeskOrder = createAsyncThunk('getDukanBillDeskOrder', async (page, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/order/?page=${page}&is_deliverd=False`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        return handleRequest(request, rejectWithValue)
    }
    catch (error) {
        return rejectWithValue(catchError(error))
    }
})

const saveOrderPdf = createAsyncThunk('saveOrderPdf', async ({bill_id, data}, { rejectWithValue }) => {
    try {
        const token = getToken()
        const request = await axios.post(`${url}/api/dukandar/order/saveOrderPdf/?bill_id=${bill_id}`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            }
        })
        return handleRequest(request, rejectWithValue)
    }
    catch(error) {
        return rejectWithValue(catchError(error))
    }
})

// -------------------------------------



const dukandarSlice = createSlice({
    name: 'dukandar',
    initialState: intialState,
    reducers: {
        dukandarDefault(state, action) {
            state.dukandarError = null
            state.dukandarSuccess = null
            state.searchedCategory = null
            state.searchedProduct = null
        },
        incDukanCategoryPage(state, action) {
            state.dukanCategoryPage = state.dukanCategoryPage + 1
        },
        incDukanUserPage(state, action) {
            state.dukanUserPage = state.dukanUserPage + 1
        },
        setCategoryName(state, action) {
            state.categoryName = action.payload
        },
        dukanOrderDefault(state , action ) {
            state.dukandarLoading = true
            state.dukanOrder = null
            state.dukanOrderPage = 1
            state.dukanOrderFirstTime = true
            state.dukanOrderLastPage = false
        },
        incDukanOrderPage(state, action) {
            action.payload === 0 ?
                state.dukanOrderFirstTime = true :
                state.dukanOrderPage = state.dukanOrderPage + 1
        },
        incDukanBillDeskOrderPage(state, action) {
            state.dukanBillDeskOrderPage = state.dukanBillDeskOrderPage  + 1
        },
        updateDukanOrder(state, action) {
            const newOrder = state.dukanOrder.map((item, index) =>{
                if(item.id === action.payload.id) {
                    return {
                        ...item,
                        is_paid : action.payload.is_paid,
                        is_deliverd : action.payload.is_deliverd,
                        total_price : action.payload.total_price,
                        additional_total : action.payload.additional_total
                    }
                }
                return {...item}
            })
            state.dukanOrder = newOrder
        },
        changeFilter(state, action) {
            if (action.payload.type === 'order') {
                state.orderFilter = {
                    ...state.orderFilter,
                    [action.payload.name]: action.payload.value
                }
            }
        },
        applyFilter(state, action) {
            if (action.payload.type === 'order') {
                state.dukanOrderFirstTime = true
                state.dukanOrderLastPage = false
                state.dukanOrder = null
                state.dukandarLoading = true
                state.dukanOrderPage = 1
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDukanUser.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
            })
            .addCase(getDukanUser.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.dukanUser = state.dukanUser === null ? action.payload.data : [...state.dukanUser, ...action.payload.data]
                state.dukanUserFirstTime = false
            })
            .addCase(getDukanUser.rejected, (state, action) => {
                state.dukandarLoading = false
                action.payload === errorName.invalidPage ? 
                state.dukanUserLastPage = true :
                state.dukandarError = action.payload
            }) // getDukanUser


            .addCase(getDukanAllCategory.pending, (state) => {
                state.dukanAllCategory = []
            })
            .addCase(getDukanAllCategory.fulfilled, (state, action) => {
                state.dukanAllCategory = action.payload.data
            })
            .addCase(getDukanAllCategory.rejected, (state, action) => {
                state.dukanAllCategory = []
                state.dukandarError = action.payload
            }) //getDukanAllCategory

            .addCase(getDukanCategory.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
            })
            .addCase(getDukanCategory.fulfilled, (state, action) => {
                state.dukanCategory = state.dukanCategory === null ? action.payload.data : [...state.dukanCategory, ...action.payload.data]
                state.dukandarLoading = false
                state.dukanCategoryFirstTime = false
            })
            .addCase(getDukanCategory.rejected, (state, action) => {
                state.dukandarLoading = false
                action.payload === errorName.invalidPage ? 
                state.dukanCategoryLastPage = true : 
                state.dukandarError = action.payload
            }) // getDukanCategory


            .addCase(addDukanCategory.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
            })
            .addCase(addDukanCategory.fulfilled, (state, action) => {
                state.dukandarSuccess = action.payload.success_message
                state.dukandarLoading = false
                state.dukanAllCategory = [...state.dukanAllCategory, action.payload.data.name]
                state.dukanCategory = [action.payload.data, ...state.dukanCategory]
            })
            .addCase(addDukanCategory.rejected, (state, action) => {
                state.dukandarError = action.payload
                state.dukandarLoading = false
            }) // addDukanCategory


            .addCase(getSearchedCategory.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.searchedCategory = null
            })
            .addCase(getSearchedCategory.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.searchedCategory = action.payload.data
            })
            .addCase(getSearchedCategory.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) // getSearchedCategory


            .addCase(updateDukanCategory.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
            })
            .addCase(updateDukanCategory.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success_message
                const list = state.dukanAllCategory.filter((item) => {
                    return item !== action.payload.name
                })
                state.dukanAllCategory = [...list, action.payload.updated]
            })
            .addCase(updateDukanCategory.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) // updateDukanCategory


            .addCase(getCategoryProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.categoryProduct = null
            })
            .addCase(getCategoryProduct.fulfilled, (state, action) => {
                state.categoryProduct = state.categoryProduct === null ? action.payload.data : [...action.payload.data, ...state.categoryProduct]
                state.dukandarLoading = false
            })
            .addCase(getCategoryProduct.rejected, (state, action) => {
                state.dukandarError = action.payload
                state.dukandarLoading = false
            }) // getCategoryProduct


            .addCase(addDukanProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
            })
            .addCase(addDukanProduct.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success_message
            })
            .addCase(addDukanProduct.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) // addDukanProduct


            .addCase(getSearchedProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
                state.searchedProduct = null
            })
            .addCase(getSearchedProduct.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.searchedProduct = action.payload.data
            })
            .addCase(getSearchedProduct.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) // getSearchedProduct

            
            .addCase(updateDukanProduct.pending, (state) => {
                state.dukandarLoading = true
                state.dukandarError = null
                state.dukandarSuccess = null
            })
            .addCase(updateDukanProduct.fulfilled, (state, action) => {
                state.dukandarLoading = false
                state.searchedProduct = null
                state.dukandarSuccess = action.payload.success_message
            })
            .addCase(updateDukanProduct.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) // updateDukanProduct



            .addCase(getDukanOrder.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
            })
            .addCase(getDukanOrder.fulfilled, (state, action) => {
                state.dukanOrder = state.dukanOrder === null ? action.payload.data : [...state.dukanOrder, ...action.payload.data]
                state.dukandarLoading = false
                state.dukandarError = null
                state.dukanOrderFirstTime = false
            })
            .addCase(getDukanOrder.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukanOrderFirstTime = false
                action.payload === errorName.invalidPage ? 
                state.dukanOrderLastPage = true : 
                state.dukandarError = action.payload
            }) //getDukanOrder


            .addCase(getDukanNotification.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
            })
            .addCase(getDukanNotification.fulfilled, (state, action) => {
                state.dukanNotification = action.payload.data
                state.dukandarLoading = false
                state.dukandarError = null
            })
            .addCase(getDukanNotification.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload === 'No notification' ? null : action.payload
            }) //getDukanNotification


            .addCase(addDukanNotification.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarError = null
            })
            .addCase(addDukanNotification.fulfilled, (state, action) => {
                state.dukanNotification = state.dukanNotification ? [action.payload.data, ...state.dukanNotification] : [action.payload.data]
                state.dukandarSuccess = action.payload.success_message
            })
            .addCase(addDukanNotification.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) //addDukanNotification


            .addCase(deleteDukanNotification.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarError = null
            })
            .addCase(deleteDukanNotification.fulfilled, (state, action) => {
                state.dukanNotification.length === 1 ? state.dukanNotification = null :  
                state.dukanNotification = state.dukanNotification.filter((item, index) => {
                    return item.id !== action.payload.id
                })            
                state.dukandarSuccess = 'Notification Successfully Deleted !'
            })
            .addCase(deleteDukanNotification.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) //deleteDukanNotification


            .addCase(getDukanProfile.pending, (state) => {
                state.dukanProfile = null
                state.dukandarLoading = true
                state.dukandarError = null
            })
            .addCase(getDukanProfile.fulfilled, (state, action) => {
                state.dukanProfile = action.payload.data
                state.dukandarLoading = false
                state.dukandarError = null
            })
            .addCase(getDukanProfile.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) //getDukanProfile


            .addCase(udpateDukanProfile.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
            })
            .addCase(udpateDukanProfile.fulfilled, (state, action) => {
                state.dukanProfile = action.payload.data
                state.dukandarLoading = false
                state.dukandarSuccess = action.payload.success
                state.dukandarError = null
            })
            .addCase(udpateDukanProfile.rejected, (state, action) => {
                state.dukandarLoading = false
                state.dukandarError = action.payload
            }) //udpateDukanProfile
            .addCase(getDukanBillDeskOrder.pending, (state) => {
                state.dukandarSuccess = null
                state.dukandarLoading = true
                state.dukandarError = null
            })
            .addCase(getDukanBillDeskOrder.fulfilled, (state, action) => {
                state.dukanOrder = state.dukanBillDeskOrder === null ? action.payload.data : [...state.dukanBillDeskOrder, ...action.payload.data]
                state.dukandarLoading = false
                state.dukandarError = null
            })
            .addCase(getDukanBillDeskOrder.rejected, (state, action) => {
                state.dukandarLoading = false
                action.payload === 'Invalid page.' ? 
                state.dukanBillDeskOrderLastPage = true : 
                state.dukandarError = action.payload
            }) //getDukanBillDeskOrder

            .addCase(saveOrderPdf.pending, (state) =>{
                state.dukandarError = null
                state.dukandarSuccess = null
            })
            .addCase(saveOrderPdf.fulfilled, (state, action) =>{
                state.dukandarSuccess = action.payload.success
                // mtlb naya order banaya hai
                if(action.payload.data) {
                    if(state.dukanOrder) {
                        state.dukanOrder = [action.payload.data, ...state.dukanOrder]
                    }
                }
                // order purana h bs pdf dal rahe h 
                else if(action.payload.pdf) {
                    const newOrder = state.dukanOrder ? state.dukanOrder.map((item, index)=>{
                        return item.id === action.payload.id ? {...item, order_pdf : `${url}/media/${action.payload.pdf}`} : {...item} 
                    }) : state.dukanOrder
                    state.dukanOrder = newOrder
                }
            })
            .addCase(saveOrderPdf.rejected, (state, action) => {
                state.dukandarError = action.payload
            })
    
    }
})

export default dukandarSlice;
export { getDukanAllCategory, getDukanCategory, addDukanCategory, getSearchedCategory, updateDukanCategory, addDukanProduct, getSearchedProduct, updateDukanProduct, getDukanUser, getDukanProfile, udpateDukanProfile, getCategoryProduct, getDukanNotification, addDukanNotification, deleteDukanNotification, getDukanOrder, getDukanBillDeskOrder, saveOrderPdf };
export const { dukandarDefault, incDukanCategoryPage, incDukanUserPage, setCategoryName, incDukanOrderPage, changeFilter, applyFilter, dukanOrderDefault, updateDukanOrder, incDukanBillDeskOrderPage } = dukandarSlice.actions