import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { ErrorUp, Sloading, SuccessUp } from '../../base/index'
import { loginUser, userDefault } from '../../redux/UserSlice'
import { SmallLogo } from '../../components/index'
import { dropdown } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { errorName, pattern } from '../../base'


function Login() {

  // variable declaration
  const user = useSelector((state) => state.userInfo.user)
  const { userError, userSuccess, userLoading } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [type, setType] = useState('password')
  const location = useLocation();
  const next = new URLSearchParams(location.search).get('redirect');
  const [redirect, setRedirect] = useState('/')
  const [checkbox, setCheckBox] = useState(false)
  const [loginData, setLoginData] = useState({
    'username': '',
    'password': '',
    'dukandar' : false
  })

  // document.addEventListener('keyup', handleEnter)
  // function handleEnter(event) {
  //   event.key === 'Enter' ? login() : <></>
  // }

  function change(e) {
    setLoginData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  function login() {
    const trimed_username = loginData['username'].replace(/\s+/g, '')
    const trimed_password = loginData['password'].replace(/\s+/g, '')

    if (trimed_username === '')
       setError('Enter username !')
    else if (!pattern.usernamePattern.test(trimed_username))
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
    else if (trimed_password.length < 6)
      setError('Enter minimum 6 digit password !')
    else
      dispatch(loginUser({ 'username': loginData.username, 'password': loginData.password, 'dukandar' : checkbox }))
  }


  // use effect hook
  useEffect(() => {
    next === null ? setRedirect('/') : setRedirect(next)
  }, [next])

  useEffect(() => {
    user !== null && userSuccess === null ? navigate('/') : <></>
  }, [user])


  useEffect(() => {
    if (userSuccess === true) {
      setSuccess('User has been successfully logged in!')
      const timeout = setTimeout(() => {
        window.location.href = redirect
      }, 100)
      return () => clearTimeout(timeout)
    }
    else if (userError === errorName.networkError || userError === errorName['404_error'] || userError === errorName.internalError) {
      dispatch(userDefault())
      setError('No connection with server! Please try again.')
    }
    else if (userError !== null) {
      dispatch(userDefault())
      setError(userError)
    }
  }, [userError, userSuccess])

  useEffect(()=>{
    dispatch(dropdown(false))
  },[])
  // return
 


  return (
    <>
    <section className='login container'>
    <Helmet>
        <title>Login</title>
      </Helmet>
          <div className='row' style={{width : '100%'}}>
            <div className='logoside col-12 col-md-6 d-flex' style={{justifyContent : 'center'}}>
              <SmallLogo />
            </div>

            <div className='formside col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1' >
              <div className='card login-card gap-2'>
                <div className='h4 text-center mb-2' style={{textDecoration : 'underline'}}>Login</div>
                <label className='h5' htmlFor="">Username : </label>
                <input className='h6' type="text" name='username' value={loginData.username} onChange={(e) => change(e)} placeholder='Enter username'/>
                <label className='h5' htmlFor="">Password : </label>
                <div style={{position : 'relative'}}>
                <input className='h6' type={`${type}`} name='password' value={loginData.password} onChange={(e) => change(e)} placeholder='Enter minimum 6 digit password' />
                {
                  loginData.password !== '' ? type === 'password' ? <i className="fa-solid fa-eye h6" style={{cursor : 'pointer', position :'absolute', top : '0', left : '92%'}} onClick={()=>setType('text')}></i> : <i className="fa-solid fa-eye-slash h6" style={{cursor : 'pointer', position :'absolute', top : '0', left : '92%'}} onClick={()=>setType('password')}></i> : <></>
                }
                </div>
                <div className='d-flex'>
                <input type='checkbox' checked = {checkbox} onChange={(e)=> setCheckBox(!checkbox)} style={{width : 'fit-content', marginRight  : '10px'}}/>
                <h1 className='h6 hindi'>दुकानदार खाता लॉगिन </h1>
                </div>
                {
                  userLoading === true ? 
                  <button className='primary-button h6 d-flex' style={{justifyContent : 'center', alignItems : 'center'}}><Sloading color={'red'} width={'20px'} height={'fit-content'}/></button> :
                  <button className='primary-button h5 pt-1 pb-1' onClick={() => login()} disabled = {userLoading ? true : false}>Login</button>
                }
                {/* <button className='primary-button h5' onClick={() => login()} disabled = {userLoading ? true : false}>userLoading === true ? <Sloading color={'red'} width={'20px'} height={'fit-content'}/> : Login</button> */}
                <div className='divider h6'>OR</div>
                <div className='h6' style={{textAlign : 'center'}} >Don't have Mahajan Kirana account ?</div>
                <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/createUser/?redirect=${redirect}`)} disabled = {userLoading ? true : false}>Create Account</button>
                <div className='h6' style={{textAlign : 'center'}}>Forgot Mahajan Kirana passowrd ?</div>
                <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/forgotPassword/?redirect=${redirect}`)} disabled = {userLoading ? true : false}>Forgot Password ?</button>
              </div>
            </div>
            {
                 error !== null ? <ErrorUp removeMessage = {setError} message = {error}/> : success !== null ? <SuccessUp removeMessage={setSuccess} message = {success}/> : <></>
            }
          </div>
    </section>
    </>
  )
}

export default Login

// (node:22420) [DEP_WEBPACK_DEV_SERVER_ON_AFTER_SETUP_MIDDLEWARE] DeprecationWarning: 'onAfterSetupMiddleware' option is deprecated. Please use the 'setupMiddlewares' option.
