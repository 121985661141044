import React, { useState, useEffect, useRef } from 'react'
import { Loading, url, Sloading, ErrorUp, SuccessUp, NoData, pattern, getToken, nextOnEnter, scrollFocus } from '../base/index'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { getSearchedProduct, updateDukanProduct, dukandarDefault } from '../redux/DukandarSlice'
import SingleProduct from './SingleProduct'

function UpdateDukanProduct() {
  const { dukandarError, dukandarSuccess, dukandarLoading, searchedProduct, dukanAllCategory } = useSelector((state) => state.dukandar)
  const [search, setSearch] = useState('')
  const [product, setProduct] = useState(null)
  const [display, setDisplay] = useState('none')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [tempImage, setTempImage] = useState(null)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const refrence = {
    categoryRef : useRef(null),
    productRef : useRef(null),
    mrpRef : useRef(null),
    priceRef : useRef(null),
    offerRef : useRef(null),
    tagRef : useRef(null),
    imageRef : useRef(null),
  }



  async function searchProduct(search) {
    setLoading(true)
    setProduct(null)
    try {
      const token = getToken()
      const request = await axios.get(`${url}/api/dukandar/product/search/?category=All-Category&product=${search}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setLoading(false)
      return request.data && request.data.data ? setProduct(request.data.data) : setDisplay('none')
    }
    catch (error) {
      setLoading(false)
      setDisplay('none')
    }
  }

  function change(e) {
    const { name, value, files } = e.target
    setData((prev) => {
      return { ...prev, [name]: files ? files.length > 0 ? files[0] : searchedProduct.image : name === 'is_active' ? !prev.is_active : value }
    })
    files ? files.length > 0 ? setTempImage(URL.createObjectURL(files[0])) : setTempImage(null) : <></>
  }

  console.log(data)
  function update() {
    const name_trimed = data.name.replace(/\s+/g, '')
    const tag_trimed = data.tag.replace(/\s+/g, '')
    if (data.category === '' || data.category === 'Select Category'){
      scrollFocus(refrence.categoryRef)
      setError('Please select category !')
    }
    else if (name_trimed === '') {
      scrollFocus(refrence.productRef)
      setError('Enter Product Name !')
    }
    else if (!pattern.categoryProductPattern.test(name_trimed)) {
      scrollFocus(refrence.productRef)
      setError(`Use only ' a-z A-Z 0-9 | () & * '  for product name !`)
    }
    else if (Number(data.mrp) <= 0 || Number(data.mrp) < Number(data.price)) {
      scrollFocus(refrence.mrpRef)
      setError('Enter valid product MRP !')
    }
    else if (Number(data.price) <= 0 || Number(data.price) > Number(data.mrp)){
      scrollFocus(refrence.priceRef)
      setError('Enter valid product price !')
    }
    else if (tag_trimed === '') {
      scrollFocus(refrence.tagRef)
      setError('Enter the product tags !')
    }
    else if (data.image === null) {
      setError('Please select product image !')
    }
    else if (!data.image.length > 0 && data.image.size > 4 * 1024 * 1025 ) 
      setError('Image size should be less then 4 MB')
    else {
      try {
        data.image.includes('/media/')
        const { image, ...newData } = data
        const formData = new FormData();
        for (const key in newData) {
          formData.append(key, data[key])
        }
        dispatch(updateDukanProduct({'product' : encodeURIComponent(data.slug), 'data': formData }))
      }
      catch (error) {
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key])
        }
        dispatch(updateDukanProduct({'product' : encodeURIComponent(data.slug), 'data': formData }))
      }
    }
  }


  useEffect(() => {
    if (search.length > 2) {
      searchProduct(search)
      setDisplay('flex')
    }
    else {
      setDisplay('none')
    }
  }, [search])



  useEffect(() => {
    searchedProduct ? setData({ ...searchedProduct, 'category_name': searchedProduct.category_name }) : setData(null)
    if (dukandarError !== null && !dukandarError.toLowerCase().includes('no product')) {
      dukandarError.toLowerCase().includes('image') ? setError('Upload valid image !') : setError(dukandarError)
      dispatch(dukandarDefault())
    }
    else if (dukandarSuccess !== null) {
      setSuccess(dukandarSuccess)
      dispatch(dukandarDefault())
    }
  }, [dukandarError, dukandarSuccess, searchedProduct])


  return (
    <div className='container'>
      {
        dukandarLoading ? <Loading /> :
          <>
            <div className='heading h4'>Update Dukan Product</div>
            
            <div className='searchlist mt-4 mb-4'>
              <div className='searchlistinput'>
                <input className="h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search product here." />
                <i className="fa-solid fa-xmark h6" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
              </div>
              <div className="searchlistdiv" style={{ display: display }}>
                <div style={{ width: '100%' }}>{
                  loading === true ?
                    <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}><Sloading width={25} height={30} color='red' /> </div> :
                    product && product.map((item, key) => {
                      return (
                        <div className="searchlistvalue" key={key} onClick={() => dispatch(getSearchedProduct({'item' : encodeURIComponent(item), 'billDesk' : false}))}>
                          <h1 className="h6" key={key}>{item} </h1>
                          <hr style={{ margin: '0px' }} />
                        </div>
                      )
                    })
                }
                </div>
              </div>
            </div>

            {
              data !== null ?
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SingleProduct item={searchedProduct} dukandar={true} tempImage = {tempImage}/>
                  </div>

                  <label className='h5 mt-4 me-3' htmlFor="">Category : </label>
                  <select ref = {refrence.categoryRef} onKeyDown = {(e)=>nextOnEnter(e, refrence.productRef)}  name='category_name' style={{ border: '1px solid black' }} value={data.category_name} onChange={(e) => change(e)}>
                    {
                      dukanAllCategory.map((item, key) => {
                        return <option key={key} value={item}>{item}</option>
                      })
                    }
                  </select>
                  <br />

                  <label className='h5 mt-4' htmlFor="">Product Name : </label>
                  <input ref = {refrence.productRef} onKeyDown={(e)=>nextOnEnter(e, refrence.mrpRef)} type='text' name='name' className='h6' value={data.name} onChange={(e) => change(e)} placeholder={data.name} />
                  <label className='h5 mt-4' htmlFor="">Product MRP : </label>
                  <input ref = {refrence.mrpRef} onKeyDown={(e)=>nextOnEnter(e, refrence.priceRef)} type='text' className='h6' name='mrp' value={data.mrp} onChange={(e) => change(e)} placeholder={data.name} />
                  <label className='h5 mt-4' htmlFor="">Product Price : </label>
                  <input ref = {refrence.priceRef} onKeyDown={(e)=>nextOnEnter(e, refrence.offerRef)} type='text' name='price' className='h6' value={data.price} onChange={(e) => change(e)} placeholder={data.name} />
                  <label className='h5 mt-4' htmlFor="">Product Offer : </label>
                  <input ref = {refrence.offerRef} onKeyDown={(e)=>nextOnEnter(e, refrence.tagRef)} type='text' name='offer' className='h6' value={data.offer} onChange={(e) => change(e)} placeholder={data.name} />
                  <label className='h5 mt-4' htmlFor="">Product Tag : </label>
                  <textarea rows={4} ref = {refrence.tagRef} onKeyDown={(e)=>nextOnEnter(e, refrence.categoryRef)} type='text' name='tag' className='h6' value={data.tag} onChange={(e) => change(e)} placeholder={data.name} />
                  <label className='h5 mt-4 me-4' htmlFor="">Active : </label>
                  <input type='checkbox' className='h6' name='is_active' checked={data.is_active} onChange={(e) => change(e)} style={{ width: 'fit-content', marginRight: '10px' }} /> <br />
                  <label className='h5 mt-4' htmlFor="">Product Image : </label>
                  <input ref = {refrence.imageRef} type='file' className='h6' name='image' style={{ borderBottom: 'none' }} onChange={(e) => change(e)} />
                  <h6 style={{fontSize : '0.74rem', color : 'red', position : 'absolute'}}>Image size : 4 MB or less</h6>
                  <button className='secondry-button h6 mt-5' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={() => update()}>Update</button>
                </div> : <NoData message='Oops! No data found for this Product.' />
            }
            {
              error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
            }
          </>
      }
    </div>
  )
}

export default UpdateDukanProduct