import { useDispatch, useSelector } from "react-redux";
import { getToken, Sloading, url } from '../base/index'
import { Link, useNavigate, NavLink } from 'react-router-dom'
import { changeTotalItem, userInfoDefault, dropdown } from "../redux/UserInfoSlice";
import { nullCategory } from "../redux/ProductSlice";
import { useEffect, useState } from "react";
import axios from "axios";
import { nullOrder } from "../redux/OrderSlice";

const baseUrl = `${url}/api`

// function
function Header() {

  // variable declaration
  const { user, total_item, userInfoLoading, notification, user_dropdown, search_dropdown, side_offcanvas, is_dukandar } = useSelector((state) => state.userInfo)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [products, setProducts] = useState([])

  async function productSearch(word) {
    setLoading(true)
    try {
      const token = getToken()
      const request = await axios.get(`${baseUrl}/product/search/?word=${word}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      request.data && setProducts(request.data.data)
      setLoading(false)
    }
    catch (error) { }
  }

  function logout() {
    dispatch(dropdown(false))
    localStorage.removeItem('token')
    dispatch(userInfoDefault())
    dispatch(changeTotalItem('set0'))
    dispatch(nullCategory())
    dispatch(nullOrder())
    window.location.href = '/'
  }


  useEffect(() => {
    if (search.trim().length > 2 ) {
      productSearch(search)
      dispatch(dropdown({ 'type': 'search_dropdown', 'value': true }))
    }
    else {
      dispatch(dropdown({ 'type': 'search_dropdown', 'value': false }))
    }
  }, [search])

  // return
  return (
    <>

      {/* navigation bar */}
      <div className="nav">
        <div className="container header">
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <i className="fa-solid fa-bars navbar-toggler" onClick={() => dispatch(dropdown({ 'type': 'side_offcanvas', 'value': true }))} href="#offcanvasExample" role="button" aria-controls="offcanvasExample"></i>
            <NavLink className="navbar-brand" to={'/'}><span>M</span><span>K</span></NavLink>
          </div>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            {
              // is_dukandar === false ?
              user !== null ?
                // d-sm-block d-none
                <div className=" d-block me-3 me-sm-2 me-md-5" style={{ position: 'relative', width: '200px' }}>
                  <input className="nav-search snsinput h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search proudcts here." />
                  <i className="h5 fa-solid fa-xmark" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', cursor: 'pointer' }}></i>
                </div> : <></>
            }
            {
              userInfoLoading === true ? <Sloading height={'auto'} width={'25px'} color={'red'} /> :
                user === null ?
                  <>
                    <Link to={'/user/loginUser/'}><button className="primary-button h6 ps-3 pe-3 pt-auto pb-auto pt-md-1 pb-md-1 me-3 ms-0 ms-md-1 ms-lg-2" style={{ width: 'fit-content' }}>Login</button></Link>
                  </> :
                  <>
                    <button onClick={() => dispatch(dropdown({ 'type': 'user_dropdown', 'value': !user_dropdown }))} className="nav-user-button ms-0 ms-sm-4 me-2 me-sm-3 me-md-4"><i className={`h5 fa-solid fa-${user[0].toLowerCase()}`}></i></button>
                  </>
            }
          </div>
        </div>

        <div className={side_offcanvas ? "offcanvas offcanvas-start show" : 'offcanvas offcanvas-start'} tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title h4" id="offcanvasExampleLabel">Menu</h5>
            <button type="button" className="btn-close" onClick={() => dispatch(dropdown({ 'type': 'side_offcanvas', 'value': false }))} data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <hr style={{ margin: '0px 0px' }}></hr>
          <div className="offcanvas-body">
            <NavLink className="h5 link" to={'/'}><span className="link-icon"><i className="h5 fa-solid fa-house fa-beat-fade"></i></span> Home</NavLink>
            {is_dukandar === false ? <>
              <NavLink className="h5 link" to={'/category/'}><span className="link-icon"><i className="h5 fa-solid fa-bars-staggered fa-beat"></i></span>Category</NavLink>
              <NavLink className="h5 link" to={'/notification/'}><span className="link-icon"><i className="h5 fa-regular fa-bell fa-shake"></i></span>Notifications {notification}</NavLink>
              <NavLink className="h5 link" to={'/product/SnapNShop/'}><span className="link-icon"><i className="h5 fa-solid fa-layer-group fa-flip"></i></span>SNS</NavLink>
              <NavLink className="h5 link" to={'/myOrders/'}><span className="link-icon"><i className="h5 fa-solid fa-clipboard-list fa-bounce"></i></span>MyOrders</NavLink>
              <NavLink className="h5 link" to={'/cartItem/'}><span className="link-icon"><i className="h5 fa-regular fa-basket-shopping fa-fade"></i></span>Cart {total_item}</NavLink>
              <NavLink className="h5 link" to={'/user/apniDukan/'}><span className="link-icon"><i className="h5 fa-solid fa-shop fa-beat"></i></span>About Apni Dukan</NavLink>
              <div className="d-none d-sm-none" style={{ position: 'relative', width: '240px' }}>
                <input className="snsinput h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search proudcts here." />
                <i className="h5 fa-solid fa-xmark" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', cursor: 'pointer' }}></i>
              </div>
              <div className={search_dropdown ? "offcanvas-list show" : 'offcanvas-list'}>
                <div className="listdiv">
                  {
                    loading === true ?
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '7px' }}><Sloading width={20} height={30} color='blue' /></div> :
                      products && products.map((item, key) => {
                        return (
                          <>
                            <Link to={`/product/${item.replace(/\s+/g, "-")}/`}> <h5 className="h6" key={key}>{item}</h5></Link>
                            <hr style={{ margin: 0 }} />
                          </>
                        )
                      })
                  }
                </div>
              </div> </> : <>
              <NavLink className="h5 link" to={'/dukandar/users/'}><span className="link-icon"><i className="h5 fa-solid fa-users fa-flip"></i></span>Users</NavLink>
              <NavLink className="h5 link" to={'/dukandar/category/'}><span className="link-icon"><i className="h5 fa-solid fa-bars-staggered fa-shake"></i></span>Category</NavLink>
              <NavLink className="h5 link" to={'/dukandar/product/'}><span className="link-icon"><i className="h5 fa-solid fa-sitemap fa-spin"></i></span>Products</NavLink>
              <NavLink className="h5 link" to={'/dukandar/orders/'}><span className="link-icon"><i className="h5 fa-solid fa-clipboard-list fa-bounce"></i></span>Orders</NavLink>
              <NavLink className="h5 link" to={'/dukandar/notification/'}><span className="link-icon"><i className="h5 fa-regular fa-bell fa-shake"></i></span>Notifications</NavLink>
              <NavLink className="h5 link" to={'/dukandar/profile/'}><span className="link-icon"><i className="h5 fa-solid fa-shop fa-beat"></i></span>Dukan Profile</NavLink>
              <NavLink className="h5 link" to={'/dukandar/billDesk/'}><span className="link-icon"><i className="h5 fa-regular fa-file-lines fa-flip"></i></span>Bill Desk</NavLink>
            </>
            }
          </div>
        </div>
      </div>

      {/* search bar dropdown */}
      <div className="container product-list">
        <div className={search_dropdown ? "list show" : 'list'} >
          <div className="listdiv">
            {
              loading === true ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '7px' }}><Sloading width={20} height={30} color='red' /></div> :
                products && products.map((item, key) => {
                  return (
                    <>
                      {is_dukandar === false ?
                        <Link to={`/product/${item.replace(/\s+/g, "-")}/`}> <h5 className="h6 ps-1 pe-1 pt-2 pb-2" key={key}>{item}</h5></Link> :
                        <Link to={`/dukandar/product/${item.replace(/\s+/g, "-")}/`}> <h5 className="h6 ps-1 pe-1 pt-2 pb-2" key={key}>{item}</h5></Link>
                      }
                      <hr style={{ margin: 0 }} />
                    </>
                  )
                })
            }
          </div>
        </div>
      </div>

      {/* profile dropdown */}
      {
        is_dukandar === false ?
          <div className="container profile-dropdown">
            <div className={user_dropdown ? "list show" : 'list'}>
              <div>
                <Link to={`/user/profile/`}><h5 className="h6">Profile</h5></Link>
                <hr style={{ margin: 0 }} />
                <h5 className="h6" onClick={() => logout()}>Log Out</h5>
              </div>
            </div>
          </div> :
          <div className="container profile-dropdown">
            <div className={user_dropdown ? "list show" : 'list'}>
              <div>
                <Link to={`/dukandar/profile/`}><h5 className="h6">Profile</h5></Link>
                <hr style={{ margin: 0 }} />
                <h5 className="h6" onClick={() => logout()}>Log Out</h5>
              </div>
            </div>
          </div>
      }

    </>

  )
}

export default Header;