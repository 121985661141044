import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dropdown } from '../../redux/UserInfoSlice'
import { getDukanProfile, udpateDukanProfile, dukandarDefault } from '../../redux/DukandarSlice'
import { userDefault } from '../../redux/UserSlice'
import { NoData, NoConnection, NotAuthorized, Loading, ErrorUp, SuccessUp, url, errorName, message, getMapSrc, scrollFocus, nextOnEnter, openLink } from '../../base/index'
import { Helmet } from 'react-helmet'
import { ResetPassword } from '../../components'


function DukanProfile() {
    const { dukanProfile, dukandarLoading, dukandarError, dukandarSuccess } = useSelector((state) => state.dukandar)
    const { userSuccess, userError, userLoading } = useSelector((state) => state.user)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const [tempOImage, setTempOImage] = useState(null)
    const [tempBImage, setTempBImage] = useState(null)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const dispatch = useDispatch()
    const path = window.location.pathname
    const refrence = {
        phoneRef: useRef(null),
        whatsappRef: useRef(null),
        linkRef: useRef(null),
        aboutRef: useRef(null),
        deliveryRef: useRef(null)
    }

    function change(e) {
        const { name, value, files } = e.target
        setData((prev) => {
            return { ...prev, [name]: name === 'banner_image' || name === 'owner_image' ? files.length > 0 ? files[0] : dukanProfile[name] : value }
        })
        name === 'banner_image' ? files.length > 0 ? setTempBImage(URL.createObjectURL((files[0]))) : setTempBImage(null) : name === 'owner_image' ? files.length > 0 ? setTempOImage(URL.createObjectURL((files[0]))) : setTempOImage(null) : <></>
    }

    function update() {
        if (data.phone_number.length !== 10) {
            scrollFocus(refrence.phoneRef)
            setError('Enter 10 digit phone number !')
        }
        else if (data.whatsapp_number.length !== 10) {
            scrollFocus(refrence.whatsappRef)
            setError('Enter 10 digit whatsapp number !')
        }
        else if (data.about.length > 120) {
            scrollFocus(refrence.aboutRef)
            setError('About word limit = 120 words !')
        }
        else if (data.delivery_detail.length > 150) {
            scrollFocus(refrence.deliveryRef)
            setError('Delivery detail word limit = 150 words !')
        }
        else if (!data.owner_image)
            setError('Please select owner image !')
        else if (!data.owner_image.length > 0 && data.owner_image.size > 4 * 1024 * 1025)
            setError('Owner image size should be less then 4 MB')
        else if (!data.banner_image)
            setError('Please select banner image !')
        else if (!data.banner_image.length > 0 && data.banner_image.size > 6 * 1024 * 1025)
            setError('Banner image size should be less then 6 MB')
        else {
            if (data.owner_image.name && data.banner_image.name) {
                const formData = new FormData();
                for (const key in data) {
                    formData.append(key, data[key]);
                }
                dispatch(udpateDukanProfile({ 'data': formData }));
            }
            else if (!data.owner_image.name && !data.banner_image.name) {
                const { owner_image, banner_image, ...newData } = data
                dispatch(udpateDukanProfile({ 'data': newData }));
            }
            else if (!data.owner_image.name) {
                const { owner_image, ...newData } = data
                const formData = new FormData();
                for (const key in newData) {
                    formData.append(key, data[key]);
                }
                dispatch(udpateDukanProfile({ 'data': formData }));
            }
            else if (!data.banner_image.name) {
                const { banner_image, ...newData } = data
                const formData = new FormData();
                for (const key in newData) {
                    formData.append(key, data[key]);
                }
                dispatch(udpateDukanProfile({ 'data': formData }));
            }
        }

    }


    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])

    useEffect(() => {
        if (dukanProfile === null) {
            dispatch(getDukanProfile())
        }
        else {
            setData(dukanProfile)
        }
    }, [dukanProfile])

    useEffect(() => {
        if (dukandarError !== errorName.networkError && dukandarError !== errorName['404Error'] && dukandarError !== errorName.internalError && dukandarError !== errorName.authenticationError && dukandarError !== errorName.notDukandar && dukandarError !== null) {
            setError(dukandarError)
            dispatch(dukandarDefault())
        }
        else if (dukandarSuccess && dukandarSuccess.includes('updated')) {
            setSuccess('Profile updated!')
            dispatch(dukandarDefault())
        }
    }, [dukandarError, dukandarSuccess])

    useEffect(() => {
        if (userError !== null) {
            if (userError !== errorName.networkError && userError !== errorName['404Error'] && userError !== errorName.internalError && userError !== errorName.authenticationError && userError !== errorName.notuser) {
                setError(userError)
                dispatch(userDefault())
            }
            else {
                setError(userError)
            }
        }
        else if (userSuccess && userSuccess.includes('Password')) {
            setSuccess('Password changed !')
            dispatch(userDefault())
        }
    }, [userError, userSuccess])


    return (
        <section>
            <Helmet>
                <title>Dukandar Profile</title>
            </Helmet>
            <div style={{ margin: 'auto', maxWidth: '1100px', width: '95%' }}>
                {
                    userLoading || dukandarLoading || userInfoLoading ? <Loading /> :
                        error === errorName.networkError || dukandarError === errorName.networkError || error === errorName['404Error'] || dukandarError === errorName['404Error'] || error === errorName.internalError || dukandarError === errorName.internalError ? <NoConnection /> :
                            error === errorName.authenticationError || dukandarError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                is_dukandar === false || error === errorName.notDukandar || dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                    data !== null ?
                                        <>
                                            <div className='dukandetail mt-3' style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                                <div className="heading h4 mt-0 mb-4">Your Dukan</div>
                                                <img src={tempBImage ? tempBImage : `${url}${data.banner_image}`} alt='banner' className='img-fluid bannerimage' style={{ margin: 'auto' }} />
                                                <div className='ownerimage m-auto mt-4'>
                                                    <img src={tempOImage ? tempOImage : `${url}${data.owner_image}`} className='img-fluid' style={{ height: '100%', width: '100%' }} alt='Malik' />
                                                </div>
                                                <h5 className='h5 heading mb-1'>{data.owner}</h5>
                                                <p className=' h6 m-auto' style={{ width: 'fit-content', textAlign: 'center' }}>{data.about}</p>
                                                <div className='dukandetail row g-3 mt-3 mb-2'>
                                                    <div className='col-sm-5 col-md-6 '>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Dukan Id : </span>MKD-{data.id}</h1>
                                                    </div>
                                                    <div className='col-sm-7 col-md-6'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Owner : </span>{data.owner}</h1>
                                                    </div>
                                                    <div className='col-sm-5 col-md-6'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Dukan : </span>{data.name}</h1>
                                                    </div>
                                                    <div className='col-sm-7 col-md-6'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Address : </span>{data.address}, {data.area}</h1>
                                                    </div>
                                                    <div className='col-sm-5 col-md-6'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Phone no. : </span>{data.phone_number}</h1>
                                                    </div>
                                                    <div className='col-sm-7 col-md-6'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Whatsapp no. : </span>{data.whatsapp_number}</h1>
                                                    </div>
                                                    <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Total Products : </span>{data.total_product}</h1>
                                                    </div>
                                                    <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Total Category : </span>{data.total_category}</h1>
                                                    </div>
                                                    <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Total Orders : </span>{data.total_order}</h1>
                                                    </div>
                                                    <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Total Users : </span>{data.total_user}</h1>
                                                    </div>
                                                    <div className='col-12'>
                                                        <h1 className='h6'><span style={{ color: 'red' }}>Delivery Details : </span></h1>
                                                        {
                                                            data.delivery_detail.split('/n').map((item, key) => {
                                                                return <p key={key} className='h6'>{key + 1}. {item}</p>
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        getMapSrc(dukanProfile.map_location) ?
                                                            <div className='col-12 dukanmap' style={{ textAlign: 'center' }}>
                                                                <h1 className='h5 mt-2 mb-3'><span style={{ color: 'red' }}>Location </span></h1>
                                                                <iframe title="mahajan kirana map" src={getMapSrc(dukanProfile.map_location)} style={{ border: "0", height: 'inherit', width: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                            </div> : <></>
                                                    }

                                                </div>
                                                <div style={{ right: '3px', position: 'absolute', bottom: '72px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1px', cursor: 'pointer' }} onClick={()=>openLink(data.whatsapp_link)}>
                                                    <i className="fa-brands fa-whatsapp  fa-2xl" style={{ lineHeight: 'normal', padding: '2px 5px', borderRadius: '30px', backgroundColor: '#00800029', color: 'green' }}></i>
                                                    <h6 style={{ fontSize: '12px', color: 'green' }}>Join Group</h6>
                                                </div>
                                                <hr />
                                            </div>


                                            <h5 className='h4 heading'>Update Dukan Profile</h5>
                                            <div className="row g-2">
                                                <div className='col-6 col-sm-4'>
                                                    <label className='h6'>Phone No. : </label>
                                                    <input ref={refrence.phoneRef} onKeyDown={(e) => nextOnEnter(e, refrence.whatsappRef)} className='h6' type='number' name='phone_number' value={data.phone_number} onChange={(e) => change(e)} placeholder='1234567890' />
                                                </div>
                                                <div className='col-6 col-sm-4 order-1'>
                                                    <label className='h6'>Whatsapp No. : </label>
                                                    <input ref={refrence.whatsappRef} onKeyDown={(e) => nextOnEnter(e, refrence.linkRef)} className='h6' type='number' name='whatsapp_number' value={data.whatsapp_number} onChange={(e) => change(e)} placeholder='1234567890' />
                                                </div>
                                                <div className='col-12 col-sm-4 order-2'>
                                                    <label className='h6'>Whatsapp Group : </label>
                                                    <input ref={refrence.linkRef} onKeyDown={(e) => nextOnEnter(e, refrence.aboutRef)} className='h6' type='url' name='whatsapp_link' value={data.whatsapp_link} onChange={(e) => change(e)} placeholder='Dukan Whatsapp group link' />
                                                </div>
                                                <div className='col-12 col-md-9 order-3'>
                                                    <label className='h6'>About : (count = {data.about.length}) </label>
                                                    <textarea ref={refrence.aboutRef} onKeyDown={(e) => nextOnEnter(e, refrence.deliveryRef)} className='h6' type='text' name='about' value={data.about} onChange={(e) => change(e)} placeholder='About your dukan' />
                                                </div>
                                                <div className='col-sm-6 col-md-3 order-5 order-md-4 mb-3 mb-sm-0'>
                                                    <label className='h6'>Owner Image :</label>
                                                    <input className='h6' type='file' style={{ borderBottom: 'none' }} name='owner_image' onChange={(e) => change(e)} />
                                                    <h6 style={{ fontSize: '0.74rem', color: 'red', position: 'absolute' }}>Image size : 4 MB or less</h6>
                                                </div>
                                                <div className='col-12 col-md-9 order-4 order-md-5'>
                                                    <label className='h6'>Delivery Detail : (count = {data.delivery_detail.length}) </label>
                                                    <textarea ref={refrence.deliveryRef} onKeyDown={(e) => nextOnEnter(e, refrence.phoneRef)} rows={5} className='h6' type='text' name='delivery_detail' value={data.delivery_detail} onChange={(e) => change(e)} placeholder='Delivery suvidha ki details /n mtlb new line' />
                                                </div>
                                                <div className='col-sm-6 col-md-3  order-last'>
                                                    <label className='h6'>Banner Image </label>
                                                    <input className='h6' type='file' style={{ borderBottom: 'none' }} name='banner_image' onChange={(e) => change(e)} />
                                                    <h6 style={{ fontSize: '0.74rem', color: 'red', position: 'absolute' }}>Image size : 6 MB or less</h6>
                                                </div>

                                            </div>
                                            <button className='secondry-button h6 ps-3 pe-3 mt-5 mt-md-3 mb-1' style={{ width: 'fit-content' }} onClick={() => update()}>Update profile</button>

                                            <hr />
                                            <ResetPassword dispatch={dispatch} />

                                        </> : <NoData message='Oops! No Dukan Found!' />
                }
                {
                    error !== null ? (<ErrorUp removeMessage={setError} message={error} />) : success !== null ? (<SuccessUp removeMessage={setSuccess} message={success} />) : <></>

                }
            </div>
        </section>
    )
}

export default DukanProfile