import React, { useEffect, useState } from 'react'
import { ErrorUp, Loading, pattern, SuccessUp } from '../base/index';
import { useSelector, useDispatch } from 'react-redux';
import { addDukanCategory, dukandarDefault } from '../redux/DukandarSlice';

function AddDukanCategory() {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [category, setCategory] = useState([])
  const [display, setDisplay] = useState('none')
  const { dukandarError, dukandarSuccess, dukandarLoading, dukanAllCategory } = useSelector((state) => state.dukandar)
  const [data, setData] = useState({
    'name': '',
    'image': null
  })

  function change(e) {
    const { name, value, files } = e.target
    setData((prev) => {
      return {
        ...prev,
        [name]: files ? files.length > 0 ? files[0] : null : value
      }
    })
  }


  function add() {
    const trimed = data.name.replace(/\s+/g, '')
    if (trimed === '')
      setError('Enter Category Name !')
    else if (!pattern.categoryProductPattern.test(trimed))
      setError(`Use only ' a-z A-Z 0-9 | () & * '  for category name !`)
    else if (!data.image)
      setError('Select Category Image !')
    else if (!data.image.length > 0 && data.image.size > 4 * 1024 * 1025 ) 
      setError('Image size should be less then 4 MB')
    else {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key])
      }
      dispatch(addDukanCategory({ 'data': formData }))
    }
  }

  useEffect(() => {
    setCategory(dukanAllCategory)
  }, [dukanAllCategory])


  useEffect(() => {
    if (data) {
      data.name.length > 0 ? setDisplay('flex') : setDisplay('none')
      setCategory(
        dukanAllCategory.filter((item) => {
          return item.toLowerCase().includes(data.name.toLocaleLowerCase())
        })
      )
    }
  }, [data])

  useEffect(() => {
    if (dukandarError !== null) {
      dukandarError.toLowerCase().includes('image') ? 
      setError('Upload a valid image !') :  setError(dukandarError)
      dispatch(dukandarDefault())
    }
    else if (dukandarSuccess !== null) {
      setSuccess(dukandarSuccess)
      dispatch(dukandarDefault())
    }
  }, [dukandarError, dukandarSuccess])
 

  return (
    <div className='container'>
      {
        dukandarLoading ? <Loading /> :
          <div style={{height : '80vh'}}>
            <div className='heading h4 mt-4 mb-5'>Add Dukan Category</div>

            <div className='searchlist mt-4 mb-4'>
              <div className='searchlistinput' style={{ width: '100%' }}>
                <label className='h5' htmlFor="">Category Name : </label>
                <input className="h6" type="text" name='name' value={data.name} onChange={(e) => change(e)} placeholder="Washing Powder 1Kg" />
                <i className="fa-solid fa-xmark h6" onClick={()=>setData((prev)=>{return{...prev, 'name' : ''}})} style={{ position: 'absolute', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
              </div>
              {
                category.length > 0 ?
                  <div className='searchlistdiv' style={{ display: display, top : '52px', left : '0', maxHeight : '270px', width : '260px' }}>
                    <div style={{ width: '100%' }}>{
                      category.map((item, key) => {
                        return (
                          <div className="searchlistvalue" key={key} onClick={() => setData((prev) => { return { ...prev, 'name': item } })}>
                            <h1 className="h6" key={key}>{item} </h1>
                            <hr style={{ margin: '0px' }} />
                          </div>
                        )
                      })}
                    </div>
                  </div> : <></>
              }
            </div>
            
            <label className='h5 mt-5 mb-2' htmlFor="">Category Image  : </label>
            <input className='h6' type="file" name='image' style={{ borderBottom: 'none' }} onChange={(e) => change(e)} />
            <h6 style={{fontSize : '0.74rem', color : 'red', position : 'absolute'}}>Image size : 4 MB or less</h6>
            <button className='secondry-button h6 mt-5' style={{ width: 'fit-content', padding: '5px 35px' }} onClick={() => add()}>Add</button>
            {
              error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
            }
          </div>
      }
    </div>
  )
}

export default AddDukanCategory