import React from 'react'


function Loading() {
    return (
        <div className='container' style={{width : '100vw!important', height : '70vh', display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column'}}>
            <h5 className='loadingmk'><span style={{color : 'orange'}}>M</span><span style={{color : 'green'}}>K</span></h5>
            <h5 className='loadingname'><span style={{color : 'orange'}}>Mahajan</span><span style={{color : 'green'}}>Kirana</span></h5>
            <div className='d-flex loadingflex'>
                <h5>Apne nagar ki kirana</h5>
                <i className="fa-solid fa-truck-fast fa-beat"></i>
            </div>
        </div>
    )
}

export default Loading;