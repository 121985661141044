import axios from "axios";
import React, { useEffect, useState } from "react";
import { Loading, NoConnection, NoData, catchError, url, NotAuthorized, getToken, errorName , message, getDate, getTime} from '../../base/index'
import { useDispatch, useSelector } from "react-redux";
import { notificationChange, dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";


function Notification() {
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const dispatch = useDispatch()
    const path = window.location.pathname

    async function getNotifications() {
        setIsLoading(true)
        setError(null)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/app/notification/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            request.data.error_message ?
                setError(request.data.error_message) :
                setData(request.data.data)
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
            setError(catchError(error))
        }
    }


    useEffect(() => {
        dispatch(dropdown(false))
        getNotifications()
    }, [dispatch])

    useEffect(() => {
        data !== null ?
            dispatch(notificationChange()) : <></>
    }, [data])


    return (
        <section>
            <Helmet>
                <title>Notifications</title>
            </Helmet>
            {
                isLoading || userInfoLoading ? <Loading /> :
                    error === errorName.networkError || error === errorName["404Error"] || error === errorName.internalError ? <NoConnection /> :
                        error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                            error === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                data !== null && data.length !== 0 ?
                                    <div className="notification container">
                                        <h4 className='h4 heading'> Notifications </h4>
                                        {
                                            data.map((item, key) => {
                                                return (
                                                    <div key={key}>
                                                        <div className="card" key={key}>
                                                            <div className="card-header h6 d-flex" style={{justifyContent : 'space-between'}}>
                                                             <h6 className="h6">{getDate(item.date)} </h6> <h6 className="h6 ms-5"> {getTime(item.date)} </h6>
                                                            </div>
                                                            <div className="card-body ps-2 pe-2 pt-2 pb-2">
                                                                <h5 className="card-title text-center text-md-start h5 mb-2" style={{ color: 'blue', textDecoration : 'underline' }}>{item.title}</h5>
                                                                <p className="card-text h6 secondry-font">{item.notification}</p>
                                                            </div>
                                                        </div>
                                                        <hr style={{ height: '1px!important' }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : <NoData message={'Oops! No notifications!'} />
            }
        </section>
    )
}

export default Notification