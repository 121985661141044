import React, { useEffect, useState } from "react";
import { url, Sloading, getToken } from "../base/index";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

function DiscountProduct() {
    const [num, setNum] = useState(5)
    const {user} = useSelector((state)=>state.userInfo)
    const [product, setProduct] = useState(null)
    const [loading , setLoading] = useState(true)
    const navigate = useNavigate()

     async function getDiscountProduct(Tdiscount, Bdiscount){
        setProduct(null)
        setLoading(true)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/user/getDiscountProduct/?Tdiscount=${Tdiscount}&Bdiscount=${Bdiscount}`, {
                headers : {
                    Authorization : `Bearer ${token}`
                }
            })
            request.data && request.data.data ?
            setProduct(request.data.data) :
            setProduct(null)
            setLoading(false)
        }
        catch (error) {
            setProduct(null)
            setLoading(false)
        }
    }

    function changeNum(num, Tdiscount, Bdiscount){
        setNum(num)
        getDiscountProduct(Tdiscount, Bdiscount)
    }

    useEffect(()=>{
        getDiscountProduct(100, 40)
    },[])
    
    return (
        <div className=" last">
            <div className="heading h4 mb-2" >Top Discount Products </div>
            <div className="d-flex mt-3" style={{width :'250px', margin : 'auto'}}>
              <h5 className={num === 5 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(5, 100, 40)} style={{borderLeft: '1px solid #00000052', borderRight: '1px solid #00000052', width: '50px' }}>{`>40%`}</h5>
              <h5 className={num === 4 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(4, 40, 30)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>30%`}</h5>
              <h5 className={num === 3 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(3, 30, 20)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>20%`}</h5>
              <h5 className={num === 2 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(2, 20, 10)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>10%`}</h5>
              <h5 className={num === 1 ? "current-btn h6 text-center pt-1 pb-1" : 'last-btn h6 text-center pt-1 pb-1'} onClick={() => changeNum(1, 10, 1)} style={{ borderRight: '1px solid #00000052', width: '50px' }}>{`>1%`}</h5>
            </div>
            {
                loading ? 
                  <div className="d-flex pt-4 pb-4" style={{justifyContent : 'center'}}> <Sloading width={35} height='auto' color={'red'}/> </div> :
                   user === null ? 
                   <div style={{textAlign : 'center'}}>
                    <h1 className='h6 mt-3 mb-3'>Login to see the discount product !</h1>
                    <button className='h6 primary-button ps-5 pe-5 pt-2 pb-2 pt-md-1 pb-md-1' style={{width : 'fit-content'}} onClick={()=>navigate('/user/loginUser/')}>Login</button>
                  </div> :
                product ? 
                    <div className="pt-1 pb-4">
                        <div className="d-flex container pt-4" style={{ overflowX: 'auto', gap: '10px', scrollbarWidth: 'none' }}>
                            {
                                product && product.map((item, key) => {
                                    return (
                                        <div key={key} className="discountproduct">
                                            {
                                                item.image !== null ? <img src={`${url}${item.image}`} style={{ width: '100%' }} alt={`${item.name}`} /> :
                                                    <img src={`${url}${item.url}`} style={{ width: '100%' }} alt={`${item.name}`} />
                                            }
                                            <div className="discountproductdetail">
                                                <Link to={`/product/${item.slug}/`} className="h6 name">{item.name}</Link>
                                                <h4 className="h6">MRP : {item.mrp} Rs.</h4>
                                                <h4 className="h6">Our Price : {item.price} Rs.</h4>
                                            </div>
                                            <div className="discount">
                                                <h4 className="h5">{item.discount}% off</h4>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> : <div className="h5" style={{textAlign : 'center', marginTop : '20px'}}> No Product Found!</div>
            }
        </div>
    )
}

export default DiscountProduct;