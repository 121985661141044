import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Loading, NotAuthorized, NoData, NoConnection, SuccessUp, ErrorUp, catchError, url, Sloading, getToken, errorName } from '../../base/index'
import { Reviews } from '../../components/index'
import { changeTotalItem, dropdown } from '../../redux/UserInfoSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

function DetailProduct() {
    const { product } = useParams()
    const [data, setData] = useState(null)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [page, setPage] = useState(1)
    const [quantity, setQuantity] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [isAdding, setIsAdding] = useState(false)
    const [offerShow, setOfferShow] = useState(false)
    const [totalReview, setTotalReview] = useState(0)
    const [avgRating, setAvgRating] = useState(0)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const dispatch = useDispatch()
    const path = window.location.pathname

    async function add() {
        if (quantity <= 0 || quantity === '')
            setError('Enter qunatity!')
        else if (quantity > 30)
            setError('Quantity can not be greater then 30')
        else {
            try {
                setIsAdding(true)
                const token = getToken()
                const request = await axios.post(`${url}/api/cartItem/`, { 'product': data.id, 'quantity': quantity },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                if (request.data.error_message) {
                    setError(request.data.error_message)
                }
                else {
                    request.data.success.includes('added') ? dispatch(changeTotalItem('inc')) : <></>
                    setSuccess(request.data.success)
                }
                setQuantity(1)
                setIsAdding(false)
            }
            catch (error) {
                setIsAdding(false)
                setError(catchError(error))
            }
        }

    }

    async function getProduct() {
        setPage(1)
        setIsLoading(true)
        try {
            const token = getToken()
            const request = await axios.get(`${url}/api/product/${encodeURIComponent(product)}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data && request.data.error_message) {
                setIsLoading(false)
                setError(request.data.error_message)
            }
            else {
                setIsLoading(false)
                setData(request.data.data)
            }
        }
        catch (error) {
            setIsLoading(false)
            setError(catchError(error))
        }
    }


    function updateAvgRating(rating) {
        setAvgRating(Number((((avgRating * totalReview) + Number(rating)) / (totalReview + 1)).toFixed(1)))
    }

    useEffect(() => {
        dispatch(dropdown(false))
        product ? getProduct() : <></>
    }, [product])

    useEffect(() => {
        if (data !== null) {
            setTotalReview(data.total_reviews)
            setAvgRating(data.avg_rating)
        }
    }, [data])

    return (
        <section>
            <Helmet>
                <title>
                    {product}
                </title>
            </Helmet>
            {
                isLoading === true || userInfoLoading ? <Loading /> :
                    error === errorName.networkError || error === errorName['404Error'] || error === errorName.internalError ? <NoConnection /> :
                        error === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(path)} /> :
                            path?.includes('dukandar') && is_dukandar === false ? <NoData message='Oops! you do not have dukandar account.' dukandar={true} /> :
                                !path?.includes('dukandar') && is_dukandar === true ? <NoData message='Oops! you do not have user account.' button={true} /> :
                                    data !== null ?
                                        <div className='container'>
                                            <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <div className='h4 heading'>Product Detail</div>
                                                <div className='detailproduct' data-aos='zoom-in'>
                                                    {data.image.includes('http') ?
                                                        <img src={`${data.image}`} style={{ width: '100%' }} alt={`${data.name}`} /> :
                                                        <img src={`${url}${data.image}`} style={{ width: '100%' }} alt={`${data.name}`} />
                                                    }
                                                    <h1 className=' mt-3 name' style={{ fontSize: '1.09rem', color: 'red', textAlign: 'center' }}>{data.name}</h1>
                                                    <h3 className='h6 mt-2'>MRP : <del>{data.mrp} Rs.</del></h3>
                                                    <h3 className='h6 mt-2'>Our Price : {data.price} Rs.</h3>
                                                    <h3 className='h6 mt-2'>Discount of {data.discount} %</h3>
                                                    {data.description !== '' ? <h4 className='h6 mt-2 text-center'>Description <br />{data.description}</h4> : <></>}
                                                    {is_dukandar === false ?
                                                        <div className='row'>
                                                            <div className='col-12 d-flex flex-row mt-3' style={{ gap: '20px' }}>
                                                                <input type='number' className='text-center h5' value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder='Enter KG' />
                                                            </div>
                                                            <div className='col-12 pt-1 pb-1 mt-3'>
                                                                <button className='primary-button h6' onClick={() => add()} disabled={isAdding || data.is_active === false ? true : false}>{data.is_active === true ? 'Add To Cart' : 'Not Available'}</button> <br />
                                                            </div>
                                                        </div> : <></>}

                                                    {
                                                        data.offer ? <div className='offer h6' onClick={() => setOfferShow(!offerShow)}><i className="fa-solid fa-layer-group fa-fade h6"></i></div> : <></>
                                                    }
                                                    <div className={offerShow ? 'offer-show' : 'offer-show hide'} style={{ fontSize: '0.8rem' }}>
                                                        {data.offer}
                                                    </div>
                                                </div>
                                            </div>
                                            <h1 className='divider h5 mt-2 mb-2'>Product Reviews</h1>
                                            <h4 className='h6'>Average rating : {avgRating}</h4>
                                            <h4 className='h6 mt-1 mb-1'>Total reviews : {totalReview}</h4>
                                            <Reviews productId={data.id} setPerror={setError} page={page} setPage={setPage} setTotalReview={setTotalReview} updateAvgRating={updateAvgRating} reviewd={data.is_reviewd} />
                                            {
                                                error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                                            }
                                        </div> : <NoData message='Oops! No product found with this name.' />
            }
        </section>
    )
}

export default DetailProduct