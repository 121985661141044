import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropdown } from "../../redux/UserInfoSlice";
import { getDukanOrder, incDukanOrderPage, applyFilter, changeFilter} from '../../redux/DukandarSlice'
import { Helmet } from "react-helmet";
import { Loading, NoData, NoConnection, NotAuthorized, errorName, message, Sloading } from "../../base/index";
import { SingleOrder } from "../../components/index";


function DukanOrder() {
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const is_dukandar = useSelector((state) => state.userInfo.is_dukandar)
    const { dukandarLoading, dukandarError, dukanOrder, dukanOrderFirstTime, dukanOrderLastPage, dukanOrderPage, orderFilter } = useSelector((state) => state.dukandar)
    const [error, setError] = useState(null)
    const [isMoreLoading, setIsMoreLoading] = useState(false)
    const path = window.location.pathname
    const dispatch = useDispatch()

    function load() {
        setIsMoreLoading(true)
        dispatch(getDukanOrder({ 'page': dukanOrderPage + 1, 'filters': orderFilter }))
        dispatch(incDukanOrderPage())
    }

    useEffect(() => {
        dispatch(dropdown(false))
        // dispatch(dukanOrderDefault())
    }, [dispatch])

    useEffect(() => {
        dukanOrderFirstTime ? dispatch(getDukanOrder({ "page": 1, 'filters': orderFilter })) : <></>
    }, [dukanOrderFirstTime])

    useEffect(() => {
        dukandarLoading === false ? setIsMoreLoading(false) : <></>
    }, [dukandarLoading])


    return (
        <section>
            <Helmet>
                <title>Dukan Orders</title>
            </Helmet>

            <div className='container'>
                {
                    (dukanOrderPage === 1 && dukandarLoading) || userInfoLoading ? <Loading /> :
                        dukandarError === errorName.networkError || error === errorName.networkError || dukandarError === errorName["404Error"] || error === errorName["404Error"] || dukandarError === errorName.internalError || error === errorName.internalError ? <NoConnection /> :
                            dukandarError === errorName.authenticationError || error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                                dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                                    <>
                                        <div className="filters row mt-3 me-0">
                                            <div className="col-6 d-flex pe-0" style={{ alignItems: 'center' }}>
                                                <label className='h6 me-2' htmlFor="">Is Deliverd : </label>
                                                <select value={orderFilter.is_deliverd} name='is_deliverd' style={{ fontSize: '12px' }} onChange={(e) => dispatch(changeFilter({ 'type': 'order', 'name': 'is_deliverd', 'value': e.target.value }))}>
                                                    <option value='all'>All</option>
                                                    <option value='True'>YES</option>
                                                    <option value='False'>NO</option>
                                                </select>
                                            </div>
                                            <div className="col-4 d-flex pe-0 ps-0" style={{ alignItems: 'center' }}>
                                                <label className='h6 me-2' htmlFor="">Is Paid : </label>
                                                <select value={orderFilter.is_paid} name='is_paid' style={{ fontSize: '12px' }} onChange={(e) => dispatch(changeFilter({ 'type': 'order', 'name': 'is_paid', 'value': e.target.value }))}>
                                                    <option value='all'>All</option>
                                                    <option value='True'>YES</option>
                                                    <option value='False'>NO</option>
                                                </select>
                                            </div>
                                            <button className="col-2 h6 secondry-button ps-3 pe-3 pt-1 pb-1" onClick={() => dispatch(applyFilter({ 'type': 'order' }))} style={{ width: 'fit-content' }}><i className="fa-solid fa-arrow-right fa-beat h6"></i></button>
                                        </div>
                                        
                                        {
                                            dukanOrder !== null ?
                                                <div className='order'>
                                                    <h1 className='heading h4 mt-2'>Dukan Orders!</h1>
                                                    {
                                                        dukanOrder.map((item, key) => {
                                                            return (
                                                                <SingleOrder item={item} key={key} setPerror={setError} is_dukandar={is_dukandar} />
                                                            )
                                                        })
                                                    }
                                                    <div className='d-flex mt-4' style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                        {
                                                            isMoreLoading ? <Sloading color={'red'} width={'50px'} height={'fit-content'} /> :
                                                                dukanOrderLastPage === false ?
                                                                    <button className='h6 secondry-button ps-2 pe-2' style={{ width: '150px' }} onClick={() => load()}>Load more</button> : <></>
                                                        }
                                                    </div>
                                                </div> : <NoData message={'Oops! No order found!'} />
                                        }
                                    </>
                }
            </div>
        </section>
    )
}

export default DukanOrder;