import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {ErrorUp, SuccessUp, Sloading } from '../../base/index'
import { forgotPassword, userDefault } from '../../redux/UserSlice'
import { SmallLogo } from '../../components/index'
import { dropdown } from '../../redux/UserInfoSlice'
import {Helmet} from 'react-helmet'
import { errorName, pattern } from '../../base'

function Forgot() {
  const user = useSelector((state) => state.userInfo.user)
  const { userError, userSuccess, isUserLoading } = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  const next = new URLSearchParams(location.search).get('redirect');
  const [redirect, setRedirect] = useState('/')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [forgotData, setForgotData] = useState({
    'username': '',
    'email': ''
  })

  // document.addEventListener('keypress', handleEnter)
  // function handleEnter(event) {
  //   event.key === 'Enter' ? forgot() : <></>
  // }

  function change(e) {
    setForgotData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }


  function forgot() {
    const trimed_username = forgotData['username'].replace(/\s+/g, '')
    const trimed_email = forgotData['email'].trim()
    if (trimed_username === '')
      setError('Enter username!')
    else if (!pattern.usernamePattern.test(trimed_username))
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
    else if (trimed_email === '' || !pattern.emailPattern.test(trimed_email))
      setError('Enter a valid email !')
    else {
      dispatch(forgotPassword({ 'username': forgotData.username, 'email': forgotData.email }))
    }
  }

  // useEffect
  useEffect(() => {
    if (userError === errorName.networkError || userError === errorName['404Error'] || userError === errorName.internalError)
      setError('No connection with server! Please try again.')
    else if (userError !== null)
      setError(userError)
    else if (userSuccess === true)
      setSuccess('New password sent to your email address! please check.')
    dispatch(userDefault())
  }, [userError, userSuccess])

  useEffect(() => {
    next === null ? setRedirect('/') : setRedirect(next)
  }, [next])

  useEffect(() => {
    user !== null && userSuccess === null ? navigate('/') : <></>
  }, [user])

  useEffect(()=>{
    dispatch(dropdown(false))
  })

  return (
    <section className='forgot container'>:
    <Helmet>
        <title style={{'fontSize' : '2px'}}>Forgot Password</title>
      </Helmet>
        <div className='logoside row' style={{width : '100%'}}>
          <div className='col-12 col-md-6 d-flex' style={{justifyContent : 'center'}}>
            <SmallLogo />
          </div>
          <div className='formside col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1' >
            <div className='card forgot-card gap-2' data-aos="fade-down" >
              <div className='h4 text-center mb-2' style={{ textDecoration: 'underline' }}>Forgot Password</div>
                <label className='h5' htmlFor="">Username : </label>
                <input className='h6' type="text" name='username' value={forgotData.username} onChange={(e) => change(e)} placeholder='Enter username'/>
                <label className='h5' htmlFor="">Email : </label>
                <input className='h6' type="email" name='email' value={forgotData.email} onChange={(e) => change(e)} placeholder='example@gmail.com'/>
                {
                  isUserLoading === true ? 
                  <button className='primary-button h5 d-flex' style={{justifyContent : 'center', alignItems : 'center'}} onClick={() => forgot()} disabled = {isUserLoading ? true : false}><Sloading color={'red'} width={'15px'} height={'fit-content'}/></button> :
                  <button className='primary-button h5 pt-1 pb-1' onClick={() => forgot()} disabled = {isUserLoading ? true : false}>Send email</button>
                }
                {/* <button className='primary-button h5' onClick={() => forgot()} disabled={isUserLoading ? true : false}>Send email</button> */}
                <div className='divider h6'>OR</div>
                <div className='h6' style={{textAlign : 'center'}}>Already have account ?</div>
                <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/loginUser/?redirect=${redirect}`)} disabled={isUserLoading ? true : false}>Login</button>
                <div className='h6' style={{textAlign : 'center'}}>Don't have Mahajan Kirana account ?</div>
                <button className='secondry-button h5 pt-1 pb-1' onClick={() => navigate(`/user/createUser/?redirect=${redirect}`)} disabled={isUserLoading ? true : false}>Create Account</button>
            </div>
            </div>
            {
                 error !== null ? <ErrorUp removeMessage = {setError} message = {error}/> : success !== null ? <SuccessUp removeMessage={setSuccess} message = {success}/> : <></>
            }
          </div>
        </section>
  )
}

      export default Forgot