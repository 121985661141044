import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { ErrorUp, SuccessUp, catchError, url, Sloading, getToken, errorName } from '../base/index'
import { changeTotalItem } from '../redux/UserInfoSlice'
import { useDispatch } from 'react-redux'

const SingleProduct = React.memo(({ item, setPerror, dukandar = null, tempImage = null }) => {
    const [quantity, setQuantity] = useState(1)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isAdding, setIsAdding] = useState(false)
    const [offerShow, setOfferShow] = useState(false)
    const dispatch = useDispatch()

    function change(e) {
        setQuantity(e.target.value)
    }

    async function add() {
        if (quantity <= 0 || quantity === '')
            setError('Enter qunatity!')
        else if(quantity > 30) 
            setError('Quantity can not be greater then 30')
        else {
            try {
                setIsAdding(true)
                const token = getToken()
                const request = await axios.post(`${url}/api/cartItem/`, { 'product': item.id, 'quantity': quantity },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                if (request.data.error_message) {
                    request.data.error_message === errorName.internalError ? setPerror(request.data.error_message) :
                    setError(request.data.error_message)
                }
                else {
                    request.data.success.includes('added') ? dispatch(changeTotalItem('inc')) : <></>
                    setSuccess(request.data.success)
                }
                setQuantity(1)
                setIsAdding(false)
            }
            catch (error) {
                setIsAdding(false)
                setPerror(catchError(error))
            }
        }

    }

    return (<>
        <div className='col-sm-6 col-lg-4'>
            <div className='singleproduct'>
                <img src={tempImage ? tempImage : item.image.includes('http') ? `${item.image}` : `${url}${item.image}`} style={{ width: '100%' }} alt={`${item.name}`} />
                <div className='singleproductdetail'>
                    { dukandar === false ? <Link to={`/product/${item.slug}/`} className='h5'>{item.name}</Link> : <Link to={`/dukandar/product/${item.slug}/`} className='h5'>{item.name}</Link> } 
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 className='h6'>MRP : <del>{item.mrp} Rs. </del></h3>
                        <h3 className='h6'>Rating : {item.avg_rating}</h3>
                    </div> <hr className='m-0' />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h3 className='h6'>Our Price : {item.price} Rs.</h3>
                        <h3 className='h6'>{item.discount} % Off</h3>
                    </div>
                    {
                        !dukandar ?
                            <div className='row'>
                                <div className=' col-5 col-md-6 d-flex flex-row' style={{ gap: '20px' }}>
                                    <input type='number' className='text-center h6' value={quantity} onChange={(e) => change(e)} />
                                </div>
                                <div className='col-7 col-md-6 pt-1 pb-1'>
                                    {
                                        isAdding === true ?
                                            <button className='primary-button h6 d-flex' disabled={true} style={{ justifyContent: 'center', alignItems: 'center', padding: '5px auto' }} ><Sloading color={'red'} width={'16px'} height={'fit-content'} /></button> :
                                            <button className='primary-button h6' style={{ padding: '5px 0px' }} disabled={item.is_active === false ? true : false} onClick={() => add()}>{item.is_active === true ? 'Add To Cart' : 'Not Available'}</button>
                                    }
                                    {/* <button className='primary-button h5' onClick={() => add()} disabled={isAdding ? true : false}>Add to cart</button> <br /> */}
                                </div>
                            </div> : <></>
                    }
                    {
                        item.offer ? <div className='offer h6' onClick={() => setOfferShow(!offerShow)}><i className="fa-solid fa-layer-group fa-fade h6"></i></div> : <></>
                    }
                    <div className={offerShow ? 'offer-show' : 'offer-show hide'} style={{ fontSize: '0.8rem' }}>
                        {item.offer}
                    </div>
                </div>
            </div>
        </div>
        {
            error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
        }
    </>
    )
})

export default SingleProduct;