import React from 'react';

const Stars = ({ rating }) => {
  const renderStars = () => {
    const stars = [];
    const roundedRating = Math.floor(rating);
    const decimalPart = rating - roundedRating;

    // Render full stars
    for (let i = 1; i <= roundedRating; i++) {
      stars.push(
          <i className=" fa-solid fa-star star filled" style={{fontSize : '9px'}} key={`star-${i}`}></i>
      );
    }

    // Render half star for ratings with decimal parts
    if (decimalPart >= 0.3 && decimalPart <= 0.9) {
      stars.push(
        <i className=" fa-regular fa-star-half-stroke star half-filled" style={{fontSize : '9px'}} key="half-star"></i>
      );
    }

    // Render empty stars for the remaining space
    const remainingStars = 5 - stars.length;
    for (let i = 1; i <= remainingStars; i++) {
      stars.push(
          <i className=" fa-regular fa-star star" style={{fontSize : '9px'}} key={`empty-star-${i}`}></i>
      );
    }

    return stars;
  };

  return <div className="star-rating ms-1">{renderStars()}</div>;
};

export default Stars;
