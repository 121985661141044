import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDukanAllCategory, getCategoryProduct, setCategoryName } from "../../redux/DukandarSlice";
import { dropdown } from "../../redux/UserInfoSlice";
import { Helmet } from "react-helmet";
import { SingleProduct, AddDukanProduct, UpdateDukanProduct } from "../../components/index";
import { Loading, NoData, NotAuthorized, NoConnection, message, errorName } from "../../base/index";
import { useLocation } from "react-router-dom";


function DukanProduct() {
  const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
  const { dukanAllCategory, categoryProduct, dukandarLoading, dukandarError, categoryName } = useSelector((state) => state.dukandar)
  const [operation, setOperation] = useState('product')
  const [display, setDisplay] = useState('none')
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const path = window.location.pathname
  const cat = new URLSearchParams(useLocation().search).get('category')


  function api(category) {
    setSearch('')
    dispatch(setCategoryName(category.replace(/\-+/g, ' ')))
    dispatch(getCategoryProduct(encodeURIComponent(category.replace(/\s+/g, "-"))))
  }

  useEffect(() => {
    cat ? api(cat) : <></>
  }, [cat])

  useEffect(() => {
    dukanAllCategory.length === 0 ? dispatch(getDukanAllCategory()) : <></>
    dispatch(dropdown(false))
  }, [dispatch])



  useEffect(() => {
    search.length < 1 ? setDisplay('none') : setDisplay('flex')
    setCategory(
      dukanAllCategory.filter((item) => {
        return item.toLowerCase().includes(search.toLowerCase())
      })
    )
  }, [search])


  return (
    <section className="dukancategory">
      <Helmet>
        <title>Dukan Product</title>
      </Helmet>

      <div style={{ overflowX: 'visible' }}>
        {
          dukandarLoading || userInfoLoading ? <Loading /> :
            dukandarError === errorName.authenticationError ? <NotAuthorized path={encodeURIComponent(path)} /> :
              dukandarError === errorName.notDukandar ? <NoData message={message.notDukandar} dukandar={true} /> :
                dukandarError === errorName.networkError || dukandarError === errorName["404Error"] || dukandarError === errorName.internalError ? <NoConnection /> :
                  <>
                    <div className='addupdateoperation'>
                      <div onClick={() => setOperation('product')} className={operation === 'product' ? 'active h6' : 'h6'}>Product</div>
                      <div onClick={() => setOperation('add')} className={operation === 'add' ? 'active h6' : 'h6'}>Add</div>
                      <div onClick={() => setOperation('update')} className={operation === 'update' ? 'active h6' : 'h6'}>Update</div>
                    </div>

                    {operation === 'product' ?
                      <div className='my-container'>
                        <div className='searchlist mt-4 mb-4'>
                          <div className='searchlistinput'>
                            <input className="h6" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Search category here." />
                            <i className="fa-solid fa-xmark h6" onClick={() => setSearch('')} style={{ position: 'absolute', top: '4px', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
                          </div>
                          {
                            category.length > 0 ?
                              <div className='searchlistdiv' style={{ display: display }}>
                                <div style={{ width: '100%' }}>
                                  {
                                    category && category.map((item, key) => {
                                      return (
                                        <div className="searchlistvalue" key={key} onClick={() => api(item)}>
                                          <h1 className="h6" key={key}>{item} </h1>
                                          <hr style={{ margin: '0px' }} />
                                        </div>
                                      )
                                    })}
                                </div>
                              </div> : <></>
                          }
                        </div>
                        {
                          categoryProduct !== null ?
                            <div >
                              <div className='heading h4 mb-2 mt-2'>{categoryName} Proudcts</div>
                              <div className='row'>
                                {
                                  categoryProduct.map((item, key) => {
                                    return (
                                      <SingleProduct item={item} key={key} dukandar={true} />
                                    )
                                  })
                                }
                              </div>
                              <h4 />

                            </div> : <NoData message={`Oops! No products found for ${categoryName} category.`} />
                        }</div> :
                      operation === 'add' ?
                        <AddDukanProduct /> :
                        <UpdateDukanProduct />} </>
        }
      </div>
    </section>
  )
}

export default DukanProduct