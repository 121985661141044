import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dropdown, changeDefaultDukan, changeTotalItem, notificationChange } from "../../redux/UserInfoSlice";
import { url, catchError, Sloading, SuccessUp, getToken, errorName, message, ErrorUp, getMapSrc, openLink } from "../../base/index";
import { Loading, NoConnection, NoData, NotAuthorized } from "../../base/index";
import axios from "axios";
import { Helmet } from "react-helmet";
import { nullCategory } from "../../redux/ProductSlice";

function ApniDukan() {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(true)

    const [dukanProfile, setDukanProfile] = useState(null)

    const path = window.location.pathname
    const baseUrl = `${url}/api/user`
    const dispatch = useDispatch()
    const { userInfoLoading, default_dukan } = useSelector((state) => state.userInfo)

    const [dukans, setDukans] = useState(null)
    const [pin, setPin] = useState('')
    const [dukan, setDukan] = useState({ 'name': '', 'id': '' })

    async function getApniDukan(dukan_id) {
        setLoading(true)
        setError(null)
        setDukanProfile(null)
        setDukans(null)
        try {
            const token = getToken()
            const request = await axios.get(`${baseUrl}/getApniDukan/?dukan_id=${dukan_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setLoading(false)
            if (request.data && request.data.error_message) {
                setError(request.data.error_message)
            }
            else {
                setDukanProfile(request.data.data)
                setDukan({ 'name': request.data.data.name, 'id': dukan_id })
            }
        }
        catch (error) {
            setLoading(false)
            setError(catchError(error))
        }
    }


    async function setApniDukan(dukan_id) {
        setLoading(true)
        setError(null)
        setSuccess(null)
        try {
            const token = getToken()
            const request = await axios.put(`${baseUrl}/setApniDukan/?dukan_id=${dukan_id}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setLoading(false)
            if (request.data && request.data.error_message) {
                setError(request.data.error_message)
            }
            else {
                dispatch(changeDefaultDukan(dukan_id))
                dispatch(changeTotalItem({ 'type': 'specific', 'total_item': request.data.total_item }))
                dispatch(notificationChange({ 'data': request.data.notification }))
                dispatch(nullCategory())
                setSuccess(request.data.success_message)
            }
        }
        catch (error) {
            setLoading(false)
            catchError(setError(error))
        }
    }



    function selectDukan(item) {
        setDukan({ 'name': item.name, 'id': item.id })
        getApniDukan(item.id)
    }


    async function searchDukan() {
        setError(null)
        setDukans(null)
        try {
            const request = await axios.get(`${url}/api/user/searchApniDukan/?pin=${pin.trim()}`)
            request.data.error_message ? setError('Oops! No dukan found with this pin.') : setDukans(request.data)
        }
        catch (error) {
            setError('Oop! No dukan found with this pin.')
        }
    }


    useEffect(() => {
        dispatch(dropdown(false))
    }, [dispatch])

    useEffect(() => {
        default_dukan ? getApniDukan(default_dukan) : setLoading(false)
    }, [default_dukan])


    return (
        <section style={{ position: 'relative', paddingBottom: '20px', overflow : 'hidden' }}>
            <Helmet>
                <title>Apni Dukan</title>
            </Helmet>
            {
                loading || userInfoLoading ? <Loading /> :
                    error === errorName.networkError || error === errorName["404Error"] || error === errorName.internalError ? <NoConnection /> :
                        error === errorName.authenticationError ? <NotAuthorized path={path} /> :
                            error === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                <>
                                    <div className="h5" style={{ textAlign: 'center', marginTop: '20px', overflow: 'hidden' }}>
                                        Apni Dukan :<div className="heading h5 mt-2"> {dukan?.name}</div>
                                    </div>
                                    {/* <button onClick={()=>sendMessage()}>sdfsd</button> */}
                                    <div style={{ maxWidth: '280px', margin: 'auto', justifyContent: 'center', overflow: 'visible' }}>
                                        <div className='searchlist'>
                                            <div className='searchlistinput ' style={{ width: '100%' }}>
                                                <input className='h6 pb-0' style={{ flex: 1 }} value={pin} onChange={(e) => setPin(e.target.value)} type='number' placeholder='Search dukan with Pin code' />
                                                {
                                                    dukans === null ?
                                                        <i className="fa-solid fa-magnifying-glass h5" onClick={() => searchDukan()} style={{ position: 'absolute', right: '3px', cursor: 'pointer', color: 'red', backgroundColor: 'white' }}></i> :
                                                        <i className="fa-solid fa-xmark h4" onClick={() => setDukans(null)} style={{ position: 'absolute', right: '9px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
                                                }
                                            </div>
                                            <div className="searchlistdiv" style={{ display: `${dukans === null ? 'none' : 'flex'}`, top: '18px', width: '100%' }}>
                                                <div style={{ width: '100%' }}>{
                                                    dukans && dukans.map((item, key) => {
                                                        return (
                                                            <div className="searchlistvalue" key={key} >
                                                                <h1 className="h6" key={key} onClick={() => selectDukan(item)}>{item.name} </h1>
                                                                <hr style={{ margin: '0px' }} />
                                                            </div>
                                                        )
                                                    })
                                                }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        dukanProfile ? <div className=' apniDukan' style={{ maxWidth: '1100px', width: '95%', margin: 'auto', }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                                <div className='dukandetail mt-3 d-flex' style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                    <img src={`${url}${dukanProfile.banner_image}`} alt='banner' className='img-fluid bannerimage' />
                                                    <div className='ownerimage m-auto mt-4'>
                                                        <img src={`${url}${dukanProfile.owner_image}`} className='img-fluid' style={{ height: '100%', width: '100%' }} alt='Malik' />
                                                    </div>

                                                    <h5 className='h5 mt2 mb-2 heading'>{dukanProfile.owner}</h5>
                                                    <p className='h6 m-auto' style={{ width: 'fit-content', textAlign: 'center' }}>{dukanProfile.about}</p>

                                                    <div className='dukandetail row g-3 mt-5'>
                                                        <div className='col-sm-5 col-md-6 '>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Dukan Id : </span>MKD-{dukanProfile.id}</h1>
                                                        </div>
                                                        <div className='col-sm-7 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Owner : </span>{dukanProfile.owner}</h1>
                                                        </div>
                                                        <div className='col-sm-5 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Dukan : </span>{dukanProfile.name}</h1>
                                                        </div>
                                                        <div className='col-sm-7 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Address : </span>{dukanProfile.address}, {dukanProfile.area}</h1>
                                                        </div>
                                                        <div className='col-sm-5 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Phone no. : </span>{dukanProfile.phone_number}</h1>
                                                        </div>
                                                        <div className='col-sm-7 col-md-6'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Whatsapp no. : </span>{dukanProfile.whatsapp_number}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Products : </span>{dukanProfile.total_product}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Category : </span>{dukanProfile.total_category}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-5 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Orders : </span>{dukanProfile.total_order}</h1>
                                                        </div>
                                                        <div className='col-6 col-sm-7 col-md-6 col-lg-3'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Total Users : </span>{dukanProfile.total_user}</h1>
                                                        </div>
                                                        <div className='col-12'>
                                                            <h1 className='h6'><span style={{ color: 'red' }}>Delivery Details : </span></h1>
                                                            {
                                                                dukanProfile.delivery_detail.split('/n').map((item, key) => {
                                                                    return <p key={key} className='h6'>{key + 1}. {item}</p>
                                                                })
                                                            }
                                                        </div>
                                                        {
                                                            getMapSrc(dukanProfile.map_location) ?
                                                                <div className='col-12 dukanmap' style={{ textAlign: 'center' }}>
                                                                    <h1 className='h5 mt-2 mb-3'><span style={{ color: 'red' }}>Location </span></h1>
                                                                    <iframe title="mahajan kirana map" src={getMapSrc(dukanProfile.map_location)} style={{ border: "0", height: 'inherit', width: '100%' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                                                </div> : <></>
                                                        }

                                                    </div>

                                                    <button className='secondry-button h6 ps-5 pe-5 pt-1 pb-1 mt-3' style={{ width: 'fit-content' }} onClick={() => setApniDukan(dukan.id, true)}>Choose Dukan</button>
                                                </div>
                                                <div style={{ right: '13px', position: 'absolute', bottom: '-6px', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1px', cursor: 'pointer' }} onClick={() => openLink(dukanProfile.whatsapp_link)}>
                                                    <i className="fa-brands fa-whatsapp  fa-2xl" style={{ lineHeight: 'normal', padding: '2px 5px', borderRadius: '30px', backgroundColor: '#00800029', color: 'green' }}></i>
                                                    <h6 style={{ fontSize: '12px', color: 'green' }}>Join Group</h6>
                                                </div>
                                            </div>
                                        </div> : <NoData message='Oops! No dukan found.' />
                                    }
                                    {
                                        error ? <ErrorUp message={error} removeMessage={setError} /> : success ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
                                    }
                                </>

            }
        </section>
    )
}

export default ApniDukan;



// async function apni_dukan(apniDukan, set = false) {
//     setLoading(true)
//     setError(null)
//     setDukanProfile(null)
//     setSuccess(null)
//     setDukans(null)
//     try {
//         const token = JSON.parse(localStorage.getItem('token'));
//         const request = await axios.get(`${baseUrl}/api/user/apniDukan/?apniDukan=${apniDukan}&set=${set}`, {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         })

//         setLoading(false)
//         if (request.data.error_message)
//             setError(request.data.error_message)
//         else if (request.data.data) {
//             setDukanProfile(request.data.data)
//             setDukan({ 'name': request.data.data.name, 'id': apniDukan })
//             if (request.data.success_message) {
//                 dispatch(changeDefaultDukan(apniDukan))
//                 dispatch(changeTotalItem({ 'type': 'specific', 'total_item': request.data.total_item }))
//                 dispatch(notificationChange({ 'data': request.data.notification }))
//                 dispatch(nullCategory())
//                 setSuccess(request.data.success_message)
//             }
//         }
//     }
//     catch (error) {
//         setLoading(false)
//         setError(catchError(error))
//     }
// }

{/* <div className={show === true ? 'dukandropdown show' : 'dukandropdown'}>
                                        <div style={{width : '100%'}}>
                                        {
                                        searchLoading ? <div style={{display : 'flex', justifyContent : 'center'}}><Sloading width={25} height={'fit-content'} color='blue'/></div>:
                                            showDukan && showDukan.map((item, key) => {
                                                return (
                                                    <div key={key}>
                                                        <h1 className='h6' onClick={() => apni_dukan(item)}>{item}</h1>
                                                        <hr className="mt-1 mb-1" />
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>  
                                    </div> */}