import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {catchError, getToken, handleRequest, url} from '../base'
import axios from 'axios'

// creating intial state

const intialState = {
    cartLoading : true,
    cartSuccess : null,
    cartError : null,
    total_price : 0,
    cart : null,
    address : null
}

const baseUrl = `${url}/api`


// creating api for the User
const getCartItem = createAsyncThunk('getCartItem', async (_, { rejectWithValue }) =>{
      try {
        const token = getToken()
        const request = await axios.get(`${baseUrl}/cartItem/`, {
            headers : {
                Authorization : `Bearer ${token}`
            },
        })
        return request.data && request.data.error_message ? 
        rejectWithValue(request.data) : request.data
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
});

const orderComplete = createAsyncThunk('orderComplete', async ({data}, { rejectWithValue }) =>{
    try {
        const token = JSON.parse(localStorage.getItem('token'));
        const request = await axios.put(`${baseUrl}/order/complete/`, data, {
            headers : {
                Authorization : `Bearer ${token}`
            },
        })
        return request.data && request?.data?.error_message ? 
               rejectWithValue(request.data.error_message) :
               request.data
     }
     catch (error) {
        return rejectWithValue(catchError(error))
     }
})

const cartSlice = createSlice({
     name : 'cart',
     initialState : intialState,
     reducers : {
        cartDefault(state, action){
            state.cartError = null
            state.cartSuccess = null
        },
        changeTotalPrice(state, action) {
            if(action.payload.type === 'del') {
                state.total_price = state.total_price - action.payload.amount
            }
            else {
                state.total_price = action.payload.amount 
            }
        },
     },
     extraReducers: (builder) => {
         builder
            .addCase(getCartItem.pending, (state) =>{
                state.cart = null
                state.cartLoading = true
                state.cartError = null
                state.cartSuccess = null
            })
            .addCase(getCartItem.fulfilled, (state, action) =>{
                state.cartLoading = false
                state.cart = action.payload.data
                state.total_price = action.payload.total_price
                state.address = action.payload.address
            })
            .addCase(getCartItem.rejected, (state, action) =>{
                 state.cartLoading = false
                 state.address = action.payload.address
                 state.cartError = action.payload
            }) //getCartItem

            
            .addCase(orderComplete.pending, (state) =>{
                 state.cartLoading = true
                 state.cartError = null
                 state.cartSuccess = null
            })
            .addCase(orderComplete.fulfilled, (state, action) =>{
                 state.cartSuccess = action.payload.success
                 state.cartLoading = false
            })
            .addCase(orderComplete.rejected, (state, action) =>{
                state.cartLoading = false
                state.cartError = action.payload
            })
     }      
})

export default cartSlice;
export { getCartItem, orderComplete };
export const { cartDefault, changeTotalPrice } = cartSlice.actions
