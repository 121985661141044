import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteDukanNotification } from '../redux/DukandarSlice'
import { getDate, getTime } from '../base/index'

const DukanNotificationList = React.memo(({ item, disable}) => {
    const dispatch = useDispatch()
    return (
        <>
            <div className="card">
                <div className="card-header h6 d-flex" style={{ justifyContent: 'space-between' }}>
                    <h1 className="h6">{item.date ? `${getDate(item.date)}, ${getTime(item.date)}`  : 'Today'}</h1>
                    <button className="h6 dukandarnotificationdelete" style={{ cursor: 'pointer', border: 'none', transition: 'all .4s ease-in-out' }} onClick={() => dispatch(deleteDukanNotification(item.id))} disabled={disable}><i className="fa-solid fa-trash h6"></i></button>
                </div>
                <div className="card-body">
                    <h5 className="card-title text-center text-md-start h5 mb-2" style={{ color: 'blue' }}>{item.title}</h5>
                    <p className="card-text h6 secondry-font">{item.notification}</p>
                </div>
            </div>
            <hr style={{ height: '1px!important' }} />
        </>
    )
})

export default DukanNotificationList