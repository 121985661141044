import React from 'react'
import logofooter from '../images/logofooter.png'
import { NavLink } from 'react-router-dom'
function Footer() {

  return (
    <div className='footer'>
        <div className='container d-flex' style={{justifyContent : 'center'}}>
          <div className='row' style={{alignItems : 'center', width : '100%'}}>
          <div className='col-5 col-sm-4 col-md-3 col-lg-4 d-flex mt-4 mb-2 ps-0 ps-sm-auto' style={{flexDirection : 'column', gap : '9px'}}>
                <h4 className='h5 footer-head'>Important Links</h4>
                <NavLink to={'/'} className='h6 footer-link'><i className="h6 fa-solid fa-arrow-right"></i> Home</NavLink>
                <NavLink to={'/category/'} className='h6 footer-link'><i className="h6 fa-solid fa-arrow-right"></i> Category</NavLink>
                <NavLink to={'/product/SnapNShop/'} className='h6 footer-link'><i className="h6 fa-solid fa-arrow-right"></i> SNS</NavLink>
                <NavLink to={'/notification/'} className='h6 footer-link'><i className="h6 fa-solid fa-arrow-right"></i> Notifications</NavLink>
                <NavLink to={'/myOrders/'} className='h6 footer-link'><i className="h6 fa-solid fa-arrow-right"></i> MyOrders</NavLink>
                <NavLink to={'/cartItem/'} className='h6 footer-link'><i className="h6 fa-solid fa-arrow-right"></i> Cart</NavLink>
              </div>
              <div className='col-7 col-sm-6 col-md-4 col-lg-4 offset-sm-2 offset-md-0 mt-4 mb-2 ps-0 pe-0 d-flex' style={{flexDirection : 'column', gap : '4px'}}>
                  <h4 className='h5 footer-head'>Owner</h4>
                  {/* <h5 className='h6 hindi'>रामनारायण पन्नालाल महाजन,</h5> */}
                  <h5 className='h6 hindi'>कमल रामनारायण महाजन </h5>
                  <h5 className='h6 hindi'>भगत सिंह मार्ग, महेश्वर</h5>
                  <h5 className='h6 hindi'>451224, मध्य प्रदेश</h5>
                  <h5 className='h6'>mahajankiranamhs@gmail.com</h5>
                  <h5 className='h6 mt-1 mb-1'>+91 9685607577</h5>
                  <h5 className='h6'>+91 6260829322</h5>
              </div>
              <div className='col-10 col-md-5 col-lg-4 offset-1 offset-md-0'>
                   <img src={logofooter} className='img-fluid' style={{maxHeight : '300px'}} alt='mahajan kirana logo'/>
                   {/* <div className='h6' style={{textAlign : 'center'}}>Created by Govinda Kamal Mahajan</div> */}
              </div>
          </div>
        </div>
    </div>
  )
}

export default Footer