import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./UserSlice";
import productSlice from "./ProductSlice";
import UserInfoSlice  from "./UserInfoSlice";
import cartSlice from "./CartSlice";
import orderSlice from "./OrderSlice";
import dukandarSlice from "./DukandarSlice";
// jb dispatch method call krte h to wo yaha aakar dekhta h kis slice k paas wo function h or jiske paas hota h waha chale jata h
const store = configureStore({
    reducer :{
        user : userSlice.reducer,  
        product : productSlice.reducer,
        userInfo : UserInfoSlice.reducer ,
        cart : cartSlice.reducer,
        order : orderSlice.reducer,
        dukandar : dukandarSlice.reducer,            
    }
})

export default store;