import React from "react";
import logo from '../images/logo.png'

function SmallLogo() {
    return (
        <div className='smalllogo mt-4 mb-4'>
                <img src={logo} className="img-fluid" alt='mahajan kirana logo'/>
        </div>
    )
}

export default SmallLogo;