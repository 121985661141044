// import everyting
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getUser, updateUser, userDefault, resetPassword } from '../../redux/UserSlice'
import { Loading, NotAuthorized, NoData, ErrorUp, SuccessUp, NoConnection, pattern, errorName, message, scrollFocus, nextOnEnter } from '../../base/index'
import { dropdown, userInfoChange } from '../../redux/UserInfoSlice'
import { Helmet } from 'react-helmet'
import { ResetPassword } from '../../components'

// main function
function Profile() {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [data, setData] = useState(null)
  const userInfoLoading = useSelector((state)=>state.userInfo.userInfoLoading)
  const { userLoading, userData, userError, userSuccess } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const refrence = {
    firstnameRef : useRef(null),
    lastnameRef : useRef(null),
    usernameRef : useRef(null),
    emailRef : useRef(null),
    phonenameRef : useRef(null),
    houseRef : useRef(null),
    areaRef : useRef(null),
    cityRef : useRef(null),
  }


  function change(e) {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }


  function update() {
    const trimed_username = data.username.trim()
    const trimed_email = data.email.trim()
    if (trimed_username === '') {
      setError('Enter username!')
      scrollFocus(refrence.usernameRef)
    }
    else if(!pattern.usernamePattern.test(trimed_username)) {
      setError("Use only these characters ' a-z A-Z 0-9 _ @ $ ' in username field !")
      scrollFocus(refrence.usernameRef)
    }
    else if (trimed_email === '' || !pattern.emailPattern.test(trimed_email)){
      setError('Enter a valid email !')
      scrollFocus(refrence.emailRef)
    }
    else if (data.phone_number.length !== 10) {
      setError('Enter 10 digits Phone number!')
      scrollFocus(refrence.phonenameRef)
    } 
    else if (data.house_no < 0 || data.house_no === ''){
      setError('Enter house number!')
      scrollFocus(refrence.houseRef)
    }
    else {
      dispatch(updateUser({'data': data }))
    }
  }


  useEffect(() => {
    dispatch(dropdown(false))
    userData === null ? dispatch(getUser()) : setData(userData)
  }, [userData])

  useEffect(() => {
    if (userSuccess === 'Data updated') {
      setSuccess('Profile updated!')
      dispatch(userInfoChange(userData?.username))
      dispatch(userDefault())
    }
    else if (userSuccess === 'Password has been reset!') {
      setSuccess('Password reset!')
      dispatch(userDefault())
    }
    else if (userError === 'username : user with this username already exists.') {
      setError('Username already exiest! Try new username!')
      dispatch(userDefault())
    }

    else if (userError !== null && userError !== errorName.networkError && userError !== errorName['404Error'] && userError !== errorName.internalError) {
      setError(userError)
      dispatch(userDefault())
    }

  }, [userSuccess, userError])


  return (
    <section className='profile container'>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      {
        userLoading || userInfoLoading ? <Loading /> :
            userError === errorName.networkError || userError === errorName['404Error'] || userError === errorName.internalError ? <NoConnection /> :
              userError === errorName.authenticationError ? <NotAuthorized path={'/'} /> :
              userError === errorName.notGrahak ?<NoData message={message.notGrahak} button={true}/> :
                <>
                  {
                    data !== null ?
                      <div className='row ps-4 pe-4 p-sm-0' style={{ rowGap: '20px' }}>
                        <div className='h4 heading'>Profile</div>
                        <div className='col-sm-6'>
                          <label className='h5'>First Name </label>
                          <input ref = {refrence.firstnameRef} onKeyDown={(e)=>nextOnEnter(e, refrence.lastnameRef)} className='h6' type='text' name='first_name' onChange={(e) => change(e)} value={data.first_name} placeholder='kamal' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>Last Name </label>
                          <input ref = {refrence.lastnameRef} onKeyDown={(e)=>nextOnEnter(e, refrence.usernameRef)} className='h6' type='text' name='last_name' onChange={(e) => change(e)} value={data.last_name} placeholder='mahajan' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>Username </label>
                          <input ref = {refrence.usernameRef} onKeyDown={(e)=>nextOnEnter(e, refrence.emailRef)} className='h6' type='text' name='username' onChange={(e) => change(e)} value={data.username} placeholder='Your username ex - kamalmahajan' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>Email </label>
                          <input ref = {refrence.emailRef} onKeyDown={(e)=>nextOnEnter(e, refrence.phonenameRef)} className='h6' type='eamil' name='email' onChange={(e) => change(e)} value={data.email} placeholder='example@gmail.com' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>Phone number </label>
                          <input ref = {refrence.phonenameRef} onKeyDown={(e)=>nextOnEnter(e, refrence.houseRef)} className='h6' type='number' name='phone_number' onChange={(e) => change(e)} value={data.phone_number} placeholder='1234567890' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>House number </label>
                          <input ref = {refrence.houseRef} onKeyDown={(e)=>nextOnEnter(e, refrence.areaRef)} className='h6' type='number' name='house_no' onChange={(e) => change(e)} value={data.house_no} placeholder='123' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>Area/Calony  </label>
                          <input ref = {refrence.areaRef} onKeyDown={(e)=>nextOnEnter(e, refrence.cityRef)} className='h6' type='text' name='area' onChange={(e) => change(e)} value={data.area} placeholder='Bhagat sing marg' />
                        </div>
                        <div className='col-sm-6'>
                          <label className='h5'>City </label>
                          <input ref = {refrence.cityRef} onKeyDown={(e)=>nextOnEnter(e, refrence.firstnameRef)} className='h6' type='text' name='city' onChange={(e) => change(e)} value={data.city} placeholder='Maheshwar' />
                        </div>
                        <div className='col-md-6 text-md-start text-center pt-2 pb-2' >
                          <button className='primary-button third-button h6 pt-1 pb-1' style={{ maxWidth: '200px' }} onClick={() => update()}>Update</button>
                        </div>

                        <hr />
                        <ResetPassword dispatch = {dispatch}/>
                        
                        {
                          error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                        }
                      </div> : <NoData message='Oops! No data found for this user.' />
                  }
                </>
      }
    </section>
  )
}

export default Profile