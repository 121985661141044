import React, { useEffect, useState } from "react";
import axios from "axios";
import { ErrorUp, Sloading, SuccessUp, catchError, url, getDate, getToken, errorName, openLink } from "../base/index";
import { SingleOrderItem } from './index'
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateDukanOrder } from "../redux/DukandarSlice";


const SingleOrder = React.memo(({ item, setPerror, is_dukandar = false }) => {
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [msg, setMsg] = useState(null)
    const [edit, setEdit] = useState(false)
    const [isPaid, setIsPaid] = useState(false)
    const [isDeliverd, setIsDeliverd] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [additionalTotal, setAdditionalTotal] = useState(0)
    const [toolbox, setToolbox] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    async function getOrderItems() {
        setIsLoading(true)
        setMsg(null)
        try {
            const newUrl = is_dukandar ? `${url}/api/dukandar/order/${item.id}/` : `${url}/api/order/${item.id}/`
            const token = getToken()
            const request = await axios.get(newUrl, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })

            request.data && request.data.error_message ? request.data.error_message === errorName.internalError ? setMsg('Oops! Can not perform action, please try again.') : setMsg(request.data.error_message) : setData(request.data.data)
            setIsLoading(false)
        }
        catch (error) {
            setIsLoading(false)
            const d = catchError(error)
            d === errorName.internalError || d === errorName.networkError || d === errorName["404Error"] ? setMsg('Oops! Can not perform action, please try again.') :
                d === errorName.authenticationError ? setMsg('Oops! Session expires, login again.') : setError(d)
        }
    }

    async function updateOrder() {
        setIsLoading(true)
        setError(null)
        setSuccess(null)
        try {
            const token = JSON.parse(localStorage.getItem('token'))
            const request = await axios.put(`${url}/api/dukandar/order/${item.id}/`, {
                'is_paid': isPaid,
                'is_deliverd': isDeliverd,
                'total_price': totalPrice,
                'additional_total': additionalTotal,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if (request.data && request.data.error_message) {
                if (request.data.error_message === errorName.internalError) {
                    setPerror(request.data.error_message)
                }
                setError(request.data.error_message)
            }
            else {
                dispatch(updateDukanOrder({ 'id': item.id, 'is_paid': isPaid, 'is_deliverd': isDeliverd, 'total_price': totalPrice, 'additional_total': additionalTotal }))
                setSuccess('Order updated !')
            }
            setEdit(false)
        }
        catch (error) {
            setIsLoading(false)
            setPerror(catchError(error))
        }
    }

    function get() {
        data === null ? getOrderItems() : <></>
    }

    function sendMsgOnWhatsapp() {
        const message = `Namaste🙏 *${item.order_username}*,
Thank you for shopping with *${item.dukan_name}*.

Your Bill Id: *MKB-${item.id}* 
Bill Total: *${item.additional_total + item.total_price}*

You can download the bill from below link: 
*${item.order_pdf.replace('http:', 'https:')}*

MahajanKirana, Apne nagar ki kirana..
     मंगाओ हर महीने का राशन, Online
     अब अपने ही नगर की किराना दुकान से
     mahajankirana.in
`
        const phoneNumber = item.order_phone_number
        const url = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}` : `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`
        window.open(url, '_blank')
    }

    useEffect(() => {
        if (item !== null) {
            setIsPaid(item.is_paid)
            setIsDeliverd(item.is_deliverd)
            setTotalPrice(item.total_price)
            setAdditionalTotal(item.additional_total)
        }
    }, [item])

    return (
        <div className='singleorder' style={{ position: 'relative', overflow: 'hidden' }} data-aos="zoom-in-down">
            <h1 className="h5 mb-2 hindi text-center" style={{ textDecoration: 'underline' }}>श्री गणेशाय नमः</h1>
            <div className='orderdetail row g-2 mt-2'>
                <div className="col-12 col-sm-9 col-md-6 col-lg-6">
                    <h6 className="h6 d-flex" style={{ alignItems: 'center' }}><i className="h5 fa-solid fa-shop me-1"></i> : {item.dukan_name}</h6>
                </div>
                <div className="col-6 col-sm-3 col-md-3 col-lg-4">
                    <h6 className="h6">Bill Id : MKB-{item.id}</h6>
                </div>
                <div className="col-6 col-sm-6 col-md-3 col-lg-2">
                    <h6 className="h6">Total Items : {item.total_item}</h6>
                </div>
                <div className="col-7 col-sm-6 col-md-6 col-lg-3">
                    <h6 className="h6">Order on : {getDate(item.created_at)}</h6>
                </div>
                <div className="col-8 col-sm-6 col-md-6 col-lg-3 o-2">
                    <h6 className="h6">Deliverd on : {isDeliverd ? item.deliverd_at ? getDate(item.deliverd_at) : getDate() : 'Not deliverd'}</h6>
                </div>
                <div className="col-sm-10 col-md-9 col-lg-4 o-sm-1 o-4 ">
                    <h6 className="h6">Name : {item.order_username}</h6>
                </div>
                <div className="col-5 col-sm-6 col-md-3 col-lg-2 o-1">
                    <h6 className="h6 d-flex" style={{ alignItems: 'center' }}><i className="h5 me-1 fa-solid fa-phone"></i> : {item.order_phone_number}</h6>
                </div>
                <div className="col-12 col-md-10 col-lg-9 o-sm-3 o-5 ">
                    <h6 className="h6 d-flex" style={{ alignItems: 'center' }}><i className="h5 fa-solid fa-house me-1"></i> : {item.order_house_no}, {item.order_address}, {item.order_city}</h6>
                </div>
                <div className="col-4 col-sm-2 col-md-2 col-lg-3 o-sm-2 o-3 ">
                    <h6 className="h6"> List : {item.order_list ? <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => openLink(item.order_list)}> show </span> : ' No'} </h6>
                </div>
                <div className="col-5 col-sm-3 col-md-3 col-lg-3 h6 o-6 o-sm-4 d-none d-sm-block">
                    Additional : {item.additional === '' ? 'No' : 'Yes'}
                </div>
                <div className="h6 col-12 col-sm-5 col-md-4 col-lg-3 o-sm-5 o-7">Additional Total : {additionalTotal}/-</div>
                <div className="h6 col-7 pe-sm-0 ps-sm-0 col-sm-4 col-md-3 col-lg-3 o-sm-6 o-8">Items Total : {totalPrice}/-</div>
                <div className="col-5 col-sm-8 col-md-2 col-lg-3 pt-1 pb-1 o-sm-7 o-9 offset-sm-2 offset-md-0">
                    <button className="primary-button h6 pt-1 pb-1" data-bs-toggle="modal" data-bs-target={`#exampleModal${item.id}`} onClick={() => get()}>Items</button>
                </div>
            </div>
            <h6 className="h6 d-flex" style={{ position: 'absolute', top: '15px', alignItems: 'center', gap: '5px' }}> Paid : {isPaid ? <i className="fa-solid fa-check h5" style={{ color: 'blue' }}></i> : <i className="fa-solid fa-xmark h5" style={{ color: 'red' }}></i>} </h6>
            <div className="toolbox" style={{ gap: toolbox ? '15px' : '0px' }}>
                <div className={toolbox ? "toolboxdata active" : "toolboxdata"}>
                    {item.order_pdf ? <div className="d-flex" style={{gap : '15px'}}>
                        <span style={{ color: 'orangered', cursor: 'pointer', fontSize : '18px' }} onClick={() => openLink(item.order_pdf)} className="fa-solid fa-up-right-from-square"></span>
                        <span style={{ color: 'green', cursor: 'pointer', lineHeight: 'none', fontWeight: 'bolder', paddingRight: '1px',  fontSize : '20px' }} onClick={() => sendMsgOnWhatsapp()} className="fa-brands fa-whatsapp" ></span>
                    </div> : <></>}
                    {is_dukandar ? <div style={{ display: 'flex', gap: '15px' }}>
                        <i className=" fa-solid fa-file-invoice " onClick={() => navigate(`/dukandar/billDesk/?bill_id=${item.id}`)} style={{ color: 'blue', cursor: 'pointer',  fontSize : '18px' }}></i>
                        <i className=" fa-solid fa-pen-to-square " onClick={() => setEdit(!edit)} style={{ color: 'red', cursor: 'pointer',  fontSize : '18px' }}></i>
                    </div> : <></>}
                </div>
                <i className={toolbox ? "fa-solid fa-toolbox  active" : "fa-solid fa-toolbox "} onClick={() => setToolbox(!toolbox)} style={{ cursor: 'pointer',  fontSize : '18px' }}></i>
            </div>

            <div className="modal fade p-0" id={`exampleModal${item.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable ms-0 me-0 ms-sm-auto me-sm-auto" >
                    <div className="modal-content" style={{ borderRadius: '10px' }} >
                        <div className="modal-header">
                            <h5 className="modal-title h5" id="exampleModalLabel">Order Items</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}> <Sloading color={'red'} width={'50px'} height={'fit-content'} style={{ margin: 'auto' }} /></div> :
                                data !== null ?
                                    <>
                                        {
                                            data.map((item, key) => {
                                                return (
                                                    <SingleOrderItem item={item} key={key} setPerror={setPerror} />
                                                )
                                            })
                                        }
                                    </> : <h1 className="h6 p-3">{msg === null ? 'Oops! No item in this order.' : msg} </h1>}

                            <div className="m-3 h6"><span className="h5" style={{ textDecoration: 'underline' }}>Additional Products</span> : {item.additional === '' ? ' No Products ' : item.additional} </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                edit ?
                    <div className='orderedit' style={{ width: '210px', position: 'absolute', top: '0', right: '0', backgroundColor: 'white', padding: '10px', border: '2px solid #6c757d', borderRadius: '15px' }}>
                        <div className='d-flex h6 ' style={{ alignItems: 'center', gap: '10px' }}> Paid : <input className='h6' style={{ width: 'fit-content' }} onClick={(e) => setIsPaid(!isPaid)} checked={isPaid} type='checkbox' /> </div>
                        <div className='d-flex h6 mb-2 mt-2' style={{ alignItems: 'center', gap: '10px' }}> Deliverd : <input className='h6' style={{ width: 'fit-content' }} onClick={(e) => setIsDeliverd(!isDeliverd)} checked={isDeliverd} type='checkbox' /> </div>
                        <div className='d-flex h6 ' style={{ alignItems: 'center', gap: '10px' }}>Items Total : <input className='h6' style={{ width: '60px' }} onChange={(e) => setTotalPrice(e.target.value)} value={totalPrice} type='number' /> </div>
                        <div className='d-flex h6 mt-2 mb-2' style={{ alignItems: 'center', gap: '5px' }}> Additional Total : <input className='h6' style={{ width: '60px' }} onChange={(e) => setAdditionalTotal(e.target.value)} value={additionalTotal} type='number' /> </div>
                        <button className='h6 secondry-button ps-3 pe-3 pt-1 pb-1' onClick={() => updateOrder()} style={{ width: 'fit-content' }}>Update</button>
                        <i className="fa-solid fa-xmark h4" onClick={() => setEdit(false)} style={{ position: 'absolute', top: '10px', right: '20px', color: 'gray', overflow: 'hidden', cursor: 'pointer' }}></i>
                    </div>
                    : <></>
            }

            {
                error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp message={success} removeMessage={setSuccess} /> : <></>
            }
        </div >
    )
})

export default SingleOrder;




