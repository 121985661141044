import React, { useEffect, useRef, useState } from 'react';
import { cartDefault, getCartItem, orderComplete } from '../../redux/CartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, NoConnection, NotAuthorized, NoData, SuccessUp, ErrorUp, errorName, message, scrollFocus, nextOnEnter } from '../../base/index'
import { CartItem } from '../../components/index';
import { useNavigate } from 'react-router-dom';
import { changeTotalItem, dropdown } from '../../redux/UserInfoSlice';
import { Helmet } from 'react-helmet';
import { getLatestOrder } from '../../redux/OrderSlice';


function Cart() {
    const { cartLoading, cartError, cartSuccess, cart, total_price, address } = useSelector((state) => state.cart)
    const orders = useSelector((state) => state.order.orders)
    const userInfoLoading = useSelector((state) => state.userInfo.userInfoLoading)
    const [error, setError] = useState(null)
    const [bigError, setBigError] = useState(null)
    const [success, setSuccess] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const path = window.location.pathname
    const [data, setData] = useState({
        'order_username': '',
        'order_house_no': '',
        'order_address': '',
        'order_city': '',
        'order_phone_number': '',
        'order_pin': '',
        'additional': '',
        'order_list': null
    })
    const refrence = {
        nameRef : useRef(null),
        houseRef : useRef(null),
        areaRef : useRef(null),
        cityRef : useRef(null),
        phoneRef : useRef(null),
        pinRef : useRef(null),
        additionalRef : useRef(null),
        listRef : useRef(null)
    }


    function change(e) {
        const { name, value, files} =  e.target
        setData((prev) => {
            return {
                ...prev,
                [name] : files ? files.length > 0 ? files[0] : null : value
            }
        })
        // if (e.target.name === 'order_list') {
        //     setData((prev) => {
        //         return {
        //             ...prev,
        //             [e.target.name]: e.target.files[0],
        //         };
        //     });
        // } else {
        //     setData((prev) => {
        //         return {
        //             ...prev,
        //             [e.target.name]: e.target.value,
        //         };
        //     });
        // }
    }

    function placeOrder() {
        setError(null)
        setSuccess(null)
        if (total_price === 0 && data.additional === '' && data.order_list === null) {
            setError('Please write additional item / upload list / add products')
            scrollFocus(refrence.additionalRef)
        }
        else if (data.order_username.trim() === '') {
            setError('Enter your name!')
            scrollFocus(refrence.nameRef)
        }
        else if (data.order_house_no <= 0) {
            setError('Enter house number!')
            scrollFocus(refrence.houseRef)
        }
        else if (data.order_address.trim() === '') {
            setError('Enter Area / Calony!')
            scrollFocus(refrence.areaRef)
        }
        else if (data.order_city.trim() === '') {
            setError('Enter city!')
            scrollFocus(refrence.cityRef)
        }
        else if (data.order_phone_number.toString().length !== 10){
            setError('Enter 10 digit whatsapp number!')
            scrollFocus(refrence.phoneRef)
        }
        else if (data.order_pin.toString().length !== 6){
            setError('Enter 6 digits pin!')
            scrollFocus(refrence.pinRef)
        }
        else {
            if (data.order_list === null) {
                dispatch(orderComplete({ 'data': data }))
            }
            else {
                const formData = new FormData();
                {
                    for (const key in data) {
                        formData.append(key, data[key])
                    }
                }
                dispatch(orderComplete({ 'data': formData }))
            }
        }
    }

    useEffect(() => {
        dispatch(dropdown(false))
        dispatch(getCartItem())
    }, [dispatch])

    useEffect(() => {
        if (address !== null) {
            setData({
                'order_username': address?.order_username,
                'order_pin': address?.order_pin,
                'order_phone_number': address?.order_phone_number === 0 ? '' : address?.order_phone_number,
                'order_city': address?.order_city,
                'order_address': address?.order_address,
                'order_house_no': address?.order_house_no === 0 ? '' : address?.order_house_no,
                'additional': '',
                'order_list': null
            })
        }
    }, [address])

    useEffect(() => {
        if (cartSuccess === 'Thank you for shopping!') {
            setSuccess(cartSuccess)
            const timeout = setTimeout(() => {
                dispatch(changeTotalItem('set0'))
                dispatch(cartDefault())
                orders !== null ? dispatch(getLatestOrder()) : <></>
                navigate('/myOrders/')
            }, [1000])
            return () => clearTimeout(timeout)
        }
        else if (cartError === 'Invalid order id!')
            setError('Can not perform action! Please try again.')
        else if (cartError === 'order_list : Upload a valid image. The file you uploaded was either not an image or a corrupted image.')
            setError('Upload valid image |')
        else if (cartError !== null) {
            setBigError(cartError)
        }
        setData({
            'order_username': address?.order_username,
            'order_pin': address?.order_pin,
            'order_phone_number': address?.order_phone_number === 0 ? '' : address?.order_phone_number,
            'order_city': address?.order_city,
            'order_address': address?.order_address,
            'order_house_no': address?.order_house_no === 0 ? '' : address?.order_house_no,
            'additional': '',
            'order_list': null
        })
        dispatch(cartDefault())
    }, [cartError, cartSuccess])


    return (
        <section>
            <Helmet>
                <title>Cart</title>
            </Helmet>
            {
                cartLoading || userInfoLoading ? <Loading /> :
                    bigError === errorName.networkError || bigError === errorName['404Error'] || bigError === errorName.internalError ? <NoConnection /> :
                        bigError === errorName.authenticationError ? <NotAuthorized path={path} /> :
                            bigError === errorName.notGrahak ? <NoData message={message.notGrahak} button={true} /> :
                                <>{
                                    cart !== null && total_price > 0 ?
                                        <div className='container cart'>
                                            <h1 className='h4 heading'>Your Cart Items!</h1>
                                            <div className='row'>
                                                {
                                                    cart.map((item, key) => {
                                                        return (
                                                            <CartItem item={item} key={key} setPerror={setBigError} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            <h1 className='h5'>Total : {total_price}</h1>
                                            <br /> <hr /> <br />
                                        </div>
                                        : <NoData message={'Oops! No product, Shop now to add in the cart.'} />
                                }
                                    <div className='container cart' style={{ overflow: 'hidden' }}>
                                        <div className='h4 heading'>Delivery Address</div>
                                        <div className='row ps-2 pe-2 ps-sm-0 pe-sm-0' data-aos="flip-right">
                                            <div className='col-sm-6 mt-2'>
                                                <label className='h5'>Full name : </label>
                                                <input ref = {refrence.nameRef} onKeyDown={(e)=>nextOnEnter(e, refrence.houseRef)} className='h6' type='text' name='order_username' onChange={(e) => change(e)} value={data.order_username} placeholder={address?.order_username ? address?.order_username : 'ex - Govinda Kamal Mahajan'} /> <br />
                                            </div>
                                            <div className='col-sm-6 mt-2'>
                                                <label className='h5'>House no. : </label>
                                                <input ref = {refrence.houseRef} onKeyDown={(e)=>nextOnEnter(e, refrence.areaRef)} className='h6' type='number' name='order_house_no' onChange={(e) => change(e)} value={data.order_house_no} placeholder={address?.order_house_no ? address.order_house_no : 'ex - 39'} /><br />
                                            </div>
                                            <div className='col-sm-6 mt-2'>
                                                <label className='h5'>Area / Calony : </label>
                                                <input ref = {refrence.areaRef} onKeyDown={(e)=>nextOnEnter(e, refrence.cityRef)} className='h6' type='text' name='order_address' onChange={(e) => change(e)} value={data.order_address} placeholder={address?.order_address ? address?.order_address : 'ex -Bhagat Sing Marg'} /> <br />
                                            </div>
                                            <div className='col-sm-6 mt-2'>
                                                <label className='h5'>City : </label>
                                                <input ref = {refrence.cityRef} onKeyDown={(e)=>nextOnEnter(e, refrence.phoneRef)} className='h6' type='text' name='order_city' onChange={(e) => change(e)} value={data.order_city} placeholder={address?.order_city ? address?.order_city : 'ex - Maheshwar'} /><br />
                                            </div>
                                            <div className='col-sm-6 mt-2'>
                                                <label className='h5'>WhatsApp number : </label>
                                                <input ref = {refrence.phoneRef} onKeyDown={(e)=>nextOnEnter(e, refrence.pinRef)} className='h6' type='number' name='order_phone_number' onChange={(e) => change(e)} value={data.order_phone_number} placeholder={address?.order_phone_number ? address?.order_phone_number : 'ex - 1234567890'} /><br />
                                            </div>
                                            <div className='col-sm-6 mt-2'>
                                                <label className='h5'>Pin : </label>
                                                <input ref = {refrence.pinRef} onKeyDown={(e)=>nextOnEnter(e, refrence.additionalRef)} className='h6' type='number' name='order_pin' onChange={(e) => change(e)} value={data.order_pin} placeholder={address?.order_pin ? address?.order_pin : 'ex - 451224'} /><br />
                                            </div>
                                            <div className='col-md-8 mt-2'>
                                                <label className='h5'>Additional products</label>
                                                <textarea ref = {refrence.additionalRef} onKeyDown={(e)=>nextOnEnter(e, refrence.nameRef)} className='h6' rows={10} name='additional' onChange={(e) => change(e)} value={data.additional} placeholder='ex - 250g besan, 30 rupay ki haldi, 100g khula ghee, 50g kaju'></textarea> <br />
                                            </div>
                                            <div className='col-md-4 mt-2'>
                                                <div className='h6 mb-2'>Have list ? Just upload here.</div>
                                                <input  type="file" id="imageInput" className='h6' name='order_list' onChange={(e) => change(e)} style={{ borderBottom: 'none' }} />
                                            </div>
                                            <div className='col-12 pt-1 pb-1 mt-3'>
                                                <button className='col-12 h6 secondry-button third-button' style={{ width: 'fit-content', padding: '6.5px 30px' }} onClick={() => placeOrder()}>Place order</button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        error !== null ? <ErrorUp removeMessage={setError} message={error} /> : success !== null ? <SuccessUp removeMessage={setSuccess} message={success} /> : <></>
                                    }
                                </>
            }
        </section>
    )
}

export default Cart;